import { useContext, useEffect, useState } from 'react';
import { styled, Box, Typography, Divider } from '@mui/material';
import { TerritoryOverviewContext } from './territoryOverviewContext';
import { Settings } from './components/Settings';
import { DemandTable } from './demand/components/DemandTable';
import { Disclaimer } from './components/Disclaimer';
import {
  isTerritoryOverviewItemsEmpty,
  isTerritoryOverviewProductEmpty,
} from './utils';
import { SupplyTable } from './supply/components/SupplyTable';
import { SupplyChart } from './supply/components/SupplyChart';
import { SkeletonChartContent } from '../../components/generic/SkeletonChartContent';
import { Distribution, disclaimerOptions } from './constants';
import { SkeletonBars } from '../../components/generic/skeletonBars';
import { RegionSelection } from '../application/appViews/field/navigation/regionSelection/RegionSelection';

const FlexColumnBase = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const ContainerBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  height: '100%',
}));

const Content = styled(FlexColumnBase)(() => ({
  width: '100%',
  minWidth: 560,
}));

const Header = styled(FlexColumnBase)(() => ({
  padding: 16,
  paddingBottom: 0,
  width: '100%',
  minWidth: 560,
  gap: 16,
}));

const Title = styled(Typography)(() => ({
  textAlign: 'left',
}));

const TableContainer = styled(FlexColumnBase)(() => ({
  paddingLeft: 16,
  maxHeight: '90%',
  overflow: 'hidden',
}));

const ChartContainer = styled(Box)(() => ({
  marginBottom: -20,
  overflow: 'hidden',
}));

const SupplyContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  overflow: 'auto',
}));

const TitleContainer = styled(FlexColumnBase)(() => ({
  gap: 10,
}));

const StyledDivider = styled(Divider)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.dividerPrimaryColor,
  margin: '2px -16px',
}));

const RegionButtonContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: 34,
}));

export const Container = () => {
  const {
    data,
    metadataStatus,
    isDataLoading,
    isDataError,
    isMetadataLoading,
    metadata,
    selectedTimeframeID,
    distributionType,
    distributionTypesAvailable,
    territoryName,
  } = useContext(TerritoryOverviewContext);

  const [disclaimerText, setDisclaimerText] = useState();

  useEffect(() => {
    if (
      (disclaimerText &&
        disclaimerText === disclaimerOptions.NO_CHILD_REGIONS) ||
      disclaimerText === disclaimerOptions.NO_CHILD_REGIONS
    ) {
      setDisclaimerText(null);
    }
    if (disclaimerText || !data || !selectedTimeframeID) {
      return;
    }

    if (!disclaimerText && isDataError) {
      setDisclaimerText(disclaimerOptions.UNHANDLED_ERROR);
      return;
    }

    const regionHasNoChildren = isTerritoryOverviewItemsEmpty(
      data,
      selectedTimeframeID
    );

    const productIsEmpty = isTerritoryOverviewProductEmpty(
      data,
      selectedTimeframeID
    );

    const isDistributionAvailable =
      distributionTypesAvailable?.supply || distributionTypesAvailable?.demand;

    if (regionHasNoChildren) {
      setDisclaimerText(disclaimerOptions.NO_CHILD_REGIONS);
    } else if (productIsEmpty || !isDistributionAvailable) {
      setDisclaimerText(disclaimerOptions.NO_PRODUCT_DATA);
    } else {
      setDisclaimerText(null);
    }
  }, [data, isDataError, selectedTimeframeID, distributionTypesAvailable]);

  useEffect(() => {
    if (!isMetadataLoading) {
      switch (metadataStatus) {
        case 200:
          setDisclaimerText(null);
          break;
        case 204:
          setDisclaimerText(disclaimerOptions.INVALID_REGION);
          break;
        case 404:
          setDisclaimerText(disclaimerOptions.NO_PRODUCT_DATA);
          break;
        default:
          setDisclaimerText(disclaimerOptions.UNHANDLED_ERROR);
          break;
      }
    }
  }, [isMetadataLoading, metadata, metadataStatus]);

  const [isDemandLoading, setIsDemandLoading] = useState(true);
  const [isSupplyLoading, setIsSupplyLoading] = useState(true);

  if (isMetadataLoading) {
    return (
      <ContainerBox
        data-testid="territory-overview-container"
        className="intercom-field-territory-overview-page"
      >
        <Content>
          <Header>
            <RegionButtonContainer>
              <RegionSelection regionName={territoryName} />
            </RegionButtonContainer>
            <TitleContainer>
              <Title variant="h3">Territory Overview</Title>
              <StyledDivider />
            </TitleContainer>
          </Header>
          <TableContainer>
            <SkeletonChartContent />
          </TableContainer>
        </Content>
      </ContainerBox>
    );
  }

  if (disclaimerText) {
    return (
      <ContainerBox
        data-testid="territory-overview-container"
        className="intercom-field-territory-overview-page"
      >
        <Content>
          <Header>
            <RegionButtonContainer>
              <RegionSelection regionName={territoryName} />
            </RegionButtonContainer>
            <TitleContainer>
              <Title variant="h3">Territory Overview</Title>
              <StyledDivider />
            </TitleContainer>
          </Header>
          <TableContainer>
            <Disclaimer disclaimerText={disclaimerText} />
          </TableContainer>
        </Content>
      </ContainerBox>
    );
  }

  return (
    <ContainerBox
      data-testid="territory-overview-container"
      className="intercom-field-territory-overview-page"
    >
      <Content>
        <Header>
          <RegionButtonContainer>
            <RegionSelection regionName={territoryName} />
          </RegionButtonContainer>
          <TitleContainer>
            <Title variant="h3">Territory Overview</Title>
            <StyledDivider />
          </TitleContainer>
        </Header>

        <TableContainer>
          <Settings
            setIsDemandLoading={setIsDemandLoading}
            setIsSupplyLoading={setIsSupplyLoading}
          />

          {isDataLoading && <SkeletonBars />}

          {!isDataLoading && (
            <>
              {distributionType === Distribution.DEMAND && (
                <DemandTable
                  isDemandLoading={isDemandLoading}
                  setIsDemandLoading={setIsDemandLoading}
                />
              )}

              {distributionType === Distribution.SUPPLY && (
                <SupplyContainer>
                  <ChartContainer data-testid="supply-chart">
                    <SupplyChart isLoading={isSupplyLoading} />
                  </ChartContainer>
                  <SupplyTable
                    isLoading={isSupplyLoading}
                    setIsLoading={setIsSupplyLoading}
                  />
                </SupplyContainer>
              )}
            </>
          )}
        </TableContainer>
      </Content>
    </ContainerBox>
  );
};
