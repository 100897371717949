import { useQuery } from 'react-query';
import { EntityType } from '../../../../clients/maptualAPI';
import { fieldApi } from '../../../../request/maptualApiClient';

export const useEntityPowerscoreContribution = ({
  entityId,
  entityType,
  projectId,
  regionId,
  objectiveId,
  shouldFetch = true,
}: {
  entityId: string;
  entityType: EntityType;
  projectId: string;
  regionId: string;
  objectiveId: string;
  shouldFetch: boolean;
}) =>
  useQuery({
    queryKey: [
      'entity-powerscore-contribution',
      entityId,
      entityType,
      projectId,
      regionId,
      objectiveId,
    ],
    queryFn: async () => {
      const response = await fieldApi.getContributionBreakdown({
        entityId,
        entityType,
        projectId,
        regionId,
        objectiveId,
      });
      return response.data;
    },
    enabled:
      !!entityId &&
      !!entityType &&
      !!projectId &&
      !!regionId &&
      !!objectiveId &&
      shouldFetch,
  });
