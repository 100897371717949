import { useRouteMatch } from 'react-router-dom';
import { styled } from '@mui/material';
import HcpInfoContainer from '../../../../hcpinfoview/hcpInfoContainer';
import {
  ModalDisplay,
  CloseButton,
  StyledCloseIcon,
} from '../../../../../components/generic/modalDisplay';

const StyledCloseButton = styled(CloseButton)(() => ({
  position: 'absolute',
  top: '16px',
  right: '16px',
  zIndex: 1,
}));

const StyledHcpInfoContainer = styled(HcpInfoContainer)(() => ({
  paddingTop: 16,
}));

export const EntityFullModal = ({ open, setOpen, snowflakeEntityId }) => {
  const match = useRouteMatch();
  const updatedMatch = {
    params: {
      ...match.params,
      snowflakeEntityId,
    },
  };

  return (
    <ModalDisplay
      isOpen={open}
      onClose={() => setOpen(false)}
      style={{ height: '100%', maxHeight: '100%', position: 'relative' }}
    >
      <StyledCloseButton
        edge="start"
        color="inherit"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(false);
        }}
        aria-label="Close"
        size="small"
      >
        <StyledCloseIcon />
      </StyledCloseButton>
      <StyledHcpInfoContainer match={updatedMatch} />
    </ModalDisplay>
  );
};
