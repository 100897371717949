/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable no-continue */
import {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
  RefObject,
} from "react";
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
} from "recyclerlistview/web";
import { styled } from "@mui/material";
import EmptyView from "../../emptyView";
import { MapListItem } from "./mapListItem";
import { useMapViewContext } from "../../contexts/mapViewContext.ts";
import { EntityPopoverProps } from "../mapView.tsx";

const StyledMapListContainer = styled("div")(({ theme }) => ({
  width: "400px",
  [theme.breakpoints.down("md")]: {
    width: "280px",
  },
  height: "100%",
  colorScheme: theme.themeColors.colorScheme,
  borderRight: `1px solid ${theme.themeColors.borderPrimaryColor}`,
}));

const StyledMapList = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "calc(100% - 52px)",
}));

const StyledMapListHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  paddingLeft: 16,
  paddingTop: 12,
  marginBottom: 4,
}));
const StyledMapListItemTitle = styled("div")(({ theme: { themeColors } }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: themeColors.primaryTextColor,
}));

const StyledMapListItemSubTitle = styled("div")(
  ({ theme: { themeColors } }) => ({
    fontSize: 14,
    fontWeight: 400,
    color: themeColors.tertiaryColor,
  })
);

const checkArrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

const elementIsVisibleInViewport = (el, parentTop) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return (
    top >= parentTop &&
    left >= 0 &&
    bottom <= innerHeight &&
    right <= innerWidth
  );
};

type MapListProps = {
  visibleMaptualList: unknown;
  visibleMaptualListLoading: boolean;
  selectedMaptualListItem: unknown;
  onSelectMaptualListItem: (
    selectedEntity: unknown,
    zoomToEntity?: boolean
  ) => void;
  groupSelected: boolean;
  isPopoverOpen: boolean;
  setIsPopoverOpen: Dispatch<SetStateAction<boolean>>;
  setIsEntityFullModalOpen: Dispatch<SetStateAction<boolean>>;
  objective: Record<string, unknown> | null;
  selectedObjectiveIndex: number | null;
  routeEntities: Record<string, unknown>[];
  setRoute: (entities: unknown) => void;
  setIsRouteLoading: Dispatch<SetStateAction<boolean>>;
  EntityPopover?: (props: EntityPopoverProps) => JSX.Element;
  mapContentContainerRef: RefObject<HTMLElement>;
};

export const MapList = ({
  visibleMaptualList: entityList,
  visibleMaptualListLoading,
  selectedMaptualListItem,
  onSelectMaptualListItem,
  groupSelected,
  isPopoverOpen,
  setIsPopoverOpen,
  setIsEntityFullModalOpen,
  objective,
  selectedObjectiveIndex,
  routeEntities,
  setRoute,
  setIsRouteLoading,
  EntityPopover,
  mapContentContainerRef,
  filteredEntityMapData,
}: MapListProps) => {
  const [entityListData, setEntityListData] = useState([]);
  const [dataProvider, setDataProvider] = useState(
    new DataProvider((r1, r2) => r1 !== r2)
  );
  const [layoutProvider, setLayoutProvider] = useState(
    new LayoutProvider(
      (index) => 0,
      (type, dim) => {
        dim.width = 385;
        dim.height = 140;
      }
    )
  );
  const [popoverTopPosition, setPopoverTopPosition] = useState(0);
  const divRef = useRef<HTMLDivElement>();

  const {
    entityPrecallInsights,
    isEntityPrecallInsightsLoading,
    entityRuleInsights,
    isEntityRuleInsightsLoading,
  } = useMapViewContext();

  const handleClose = () => {
    setIsPopoverOpen(false);
  };

  useEffect(() => setPopoverTopPosition(0), [selectedMaptualListItem]);

  useEffect(() => {
    const getEntityInsightToDisplay = (
      snowflakeEntityId,
      entityPrecallInsights,
      entityRuleInsights
    ) => {
      const entityPrecallInsight =
        entityPrecallInsights.length > 0
          ? entityPrecallInsights?.find(
              (entity) => entity.entity_id == snowflakeEntityId
            )
          : null;
      const precallInsightToDispaly = entityPrecallInsight?.insights?.find(
        (insight) => insight.priority === 1
      );

      const ruleInsight = entityRuleInsights?.[snowflakeEntityId]
        ? {
            title: entityRuleInsights[snowflakeEntityId].title,
            description: entityRuleInsights[snowflakeEntityId].message,
          }
        : null;
      return precallInsightToDispaly || ruleInsight || null;
    };

    if (
      isEntityPrecallInsightsLoading ||
      isEntityRuleInsightsLoading ||
      !entityList?.items
    )
      return;

    const listData = [];
    const filteredEntityMapDataMapping = filteredEntityMapData.reduce(
      (acc, item) => {
        acc[item.snowflakeEntityId] = item?.address;
        return acc;
      },
      {}
    );
    for (const item of entityList.items) {
      if (!item || !item.snowflakeEntityId) continue;

      const insight = getEntityInsightToDisplay(
        item.snowflakeEntityId,
        entityPrecallInsights,
        entityRuleInsights?.data
      );
      const entityListItem = {
        primaryText: item.primaryText,
        secondaryText: item.secondaryText,
        maptualDisplayScore: item.maptualDisplayScore,
        maptualScoreChange: item.maptualScoreChange,
        objectiveScores: item.objectiveScores || [item.maptualDisplayScore],
        insight: {
          title: insight?.title,
          description: insight?.description,
        },
        snowflakeEntityId: item.snowflakeEntityId,
        segments: item.segments,
        address: filteredEntityMapDataMapping[item.snowflakeEntityId],
      };
      listData.push(entityListItem);
    }
    setEntityListData(listData);
  }, [entityPrecallInsights, entityRuleInsights, entityList]);

  const isLoading =
    !entityList?.items ||
    !checkArrayEqual(
      entityList.items.map((i) => i?.snowflakeEntityId),
      entityListData?.map((i) => i?.snowflakeEntityId)
    ) ||
    visibleMaptualListLoading ||
    isEntityPrecallInsightsLoading;

  const selectedEntityData = entityListData?.find(
    (item) =>
      item.snowflakeEntityId === selectedMaptualListItem?.snowflakeEntityId
  );

  const scrollToSelectedEntity = (entityData) => {
    const entityElement = document.getElementById(
      `map-list-item-${entityData.snowflakeEntityId}`
    );
    if (!entityElement) return;
    const { top: parentTop } =
      entityElement.parentElement.getBoundingClientRect();
    if (!elementIsVisibleInViewport(entityElement, parentTop)) {
      entityElement.scrollIntoView();
    }
    const { top } = entityElement.getBoundingClientRect();
    const topToParent = top - parentTop;
    if (top && popoverTopPosition !== topToParent) {
      setPopoverTopPosition(topToParent);
    }
  };

  if (selectedEntityData && !isLoading) {
    scrollToSelectedEntity(selectedEntityData);
  }

  useEffect(() => {
    let newEntityListData = entityListData.slice();
    if (selectedMaptualListItem) {
      const activeIndex = entityListData.findIndex(
        (item) =>
          item.snowflakeEntityId === selectedMaptualListItem.snowflakeEntityId
      );

      newEntityListData[activeIndex] = {
        ...entityListData[activeIndex],
        active: true,
      };
    }
    setDataProvider(dataProvider.cloneWithRows(newEntityListData));
  }, [entityListData, selectedMaptualListItem]);

  const isPopoverDisplayable =
    !isLoading && isPopoverOpen && selectedEntityData?.snowflakeEntityId;

  return (
    <StyledMapListContainer ref={divRef}>
      {isLoading && <EmptyView isLoading />}
      {!isLoading && (
        <>
          <StyledMapListHeader>
            <StyledMapListItemTitle>
              {groupSelected ? "Selection" : "All"}
            </StyledMapListItemTitle>
            <StyledMapListItemSubTitle>
              {entityList.items.length}
            </StyledMapListItemSubTitle>
          </StyledMapListHeader>
          {entityListData.length > 0 && (
            <StyledMapList>
              <RecyclerListView
                key={routeEntities.map((e) => e.snowflakeEntityId).join()}
                suppressBoundedSizeException={true}
                dataProvider={dataProvider}
                layoutProvider={layoutProvider}
                rowRenderer={(type, data) => (
                  <MapListItem
                    item={data}
                    onSelectMaptualListItem={onSelectMaptualListItem}
                    key={data.snowflakeEntityId}
                    selectedObjectiveIndex={selectedObjectiveIndex}
                    routeEntities={routeEntities}
                    setRoute={setRoute}
                    setIsRouteLoading={setIsRouteLoading}
                  />
                )}
              />
            </StyledMapList>
          )}
          {!entityListData ||
            (entityListData.length === 0 && (
              <StyledMapList>
                <StyledMapListHeader>
                  <StyledMapListItemSubTitle>
                    No HCPs found. Try adjusting your filters or navigating to a
                    different area.
                  </StyledMapListItemSubTitle>
                </StyledMapListHeader>
              </StyledMapList>
            ))}
        </>
      )}
      {isPopoverDisplayable && EntityPopover && (
        <EntityPopover
          isOpen={isPopoverDisplayable}
          anchorEl={divRef.current || undefined}
          setIsEntityFullModalOpen={setIsEntityFullModalOpen}
          objective={objective}
          entityData={selectedEntityData}
          topPosition={popoverTopPosition}
          handleClose={handleClose}
          routeEntities={routeEntities}
          setRoute={setRoute}
          setIsRouteLoading={setIsRouteLoading}
          mapContentContainerRef={mapContentContainerRef}
        />
      )}
    </StyledMapListContainer>
  );
};
