import { useState, useEffect } from "react";
import { styled, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SpecialtyFilter } from "./specialtyFilter";
import { CustomListFilter } from "./customListFilter";
import { Filters } from "../mapView";
import { PowerscoreFilter } from "./powerscoreFilter";
import { NotContactedFilter } from "./notContactedFilter";

const FILTER_MENU_WIDTH = 532;

const FilterContainer = styled("div", {
  shouldForwardProp: (prop) => !["isOpenChanged", "isOpen"].includes(prop),
})(({ isOpenChanged, isOpen, theme: { themeColors } }) => ({
  display: "flex",
  width: FILTER_MENU_WIDTH,
  maxWidth: FILTER_MENU_WIDTH,
  flexDirection: "column",
  height: "100%",
  zIndex: 2,
  position: "absolute",
  marginLeft: FILTER_MENU_WIDTH * -1,
  backgroundColor: themeColors.mainBackground,
  color: themeColors.primaryTextColor,
  boxShadow: `0px 16px 70px 0px rgba(0, 0, 0, 0.50)`,
  ...(isOpenChanged && {
    animation: isOpen ? "0.5s slideFilter" : "0.5s slideFilterOut",
  }),
  animationFillMode: "forwards",
  "@keyframes slideFilter": {
    from: { marginLeft: FILTER_MENU_WIDTH * -1 },
    to: { marginLeft: "0px" },
  },
  "@keyframes slideFilterOut": {
    from: { marginLeft: "0px" },
    to: { marginLeft: FILTER_MENU_WIDTH * -1 },
  },
}));

const FilterHeader = styled("div")(({ theme: { themeColors } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px",
  gap: "8px",
  width: "100%",
  borderBottom: `1px solid ${themeColors.dividerPrimaryColor}`,
}));

const FilterBody = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "0px 24px",
  width: "100%",
  overflowY: "auto",
  paddingBottom: "24px",
}));

export const FilterSection = styled("div")(({ theme: { themeColors } }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "16px 0px 24px",
  alignItems: "flex-start",
  borderBottom: `1px solid ${themeColors.borderLowContrast}`,
  "&.LastSection": {
    borderBottom: "none",
    paddingBottom: 0,
  },
}));

const FilterTitle = styled(Typography)(() => ({
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "120%",
}));

const StyledCancelIconButton = styled(IconButton)(() => ({
  backgroundColor: "none",
  border: "1px solid transparent",
  borderRadius: "5px",
}));

interface FilterSidebarProps {
  isFilterDrawerOpen: boolean;
  setIsFilterDrawerOpen: (arg0: boolean) => void;
  filters: Filters;
  setFilters: (arg0: Filters) => void;
}

export const FilterSidebar = ({
  isFilterDrawerOpen,
  setIsFilterDrawerOpen,
  filters,
  setFilters,
}: FilterSidebarProps) => {
  const [prevIsFilterDrawerOpen, setPrevIsFilterDrawerOpen] = useState(false);
  const [filterOpenChanged, setFilterOpenChanged] = useState(false);

  useEffect(() => {
    if (!!isFilterDrawerOpen !== !!prevIsFilterDrawerOpen) {
      setFilterOpenChanged(true);
      setPrevIsFilterDrawerOpen(!!isFilterDrawerOpen);
    } else {
      setFilterOpenChanged(false);
    }
  }, [isFilterDrawerOpen]);

  return (
    <FilterContainer
      data-testid="filter-sidebar"
      isOpen={isFilterDrawerOpen}
      isOpenChanged={filterOpenChanged}
    >
      <FilterHeader>
        <FilterTitle>Filters</FilterTitle>
        <StyledCancelIconButton onClick={() => setIsFilterDrawerOpen(false)}>
          <CloseIcon />
        </StyledCancelIconButton>
      </FilterHeader>
      <FilterBody>
        <NotContactedFilter filters={filters} setFilters={setFilters} />
        <PowerscoreFilter filters={filters} setFilters={setFilters} />
        <SpecialtyFilter filters={filters} setFilters={setFilters} />
        <CustomListFilter filters={filters} setFilters={setFilters} />
      </FilterBody>
    </FilterContainer>
  );
};
