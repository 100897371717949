import { useQuery } from 'react-query';
import { METRIC_DATA_TYPE, SHORT_FORM_CADENCE } from '../../constants';
import { fieldApi } from '../../../../../request/maptualApiClient';
import {
  EntityType,
  ProductPerformanceChartResponse,
} from '../../../../../clients/maptualAPI';

interface ProductPerformanceDataParams {
  entityId: string;
  projectId: string;
  regionId: string;
  objectiveId: string;
  entityType: EntityType;
  cadence: SHORT_FORM_CADENCE;
  metric: string;
  metricDataType: METRIC_DATA_TYPE;
}

export const useProductPerformanceData = ({
  projectId,
  regionId,
  objectiveId,
  entityId,
  entityType,
  metricDataType,
  metric,
  cadence,
}: ProductPerformanceDataParams): {
  data?: ProductPerformanceChartResponse;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { data, isLoading, refetch, isError, isIdle } = useQuery({
    queryKey: [
      'product-performance-data',
      projectId,
      regionId,
      objectiveId,
      entityId,
      metricDataType,
      metric,
      cadence,
      entityType,
    ],
    queryFn: async () => {
      const response = await fieldApi.getProductPerformanceChart({
        projectId,
        regionId,
        objectiveId,
        entityId,
        entityType,
        metricDataType,
        metric,
        cadence,
      });

      return response.data;
    },
    enabled: !!(
      projectId &&
      regionId &&
      objectiveId &&
      entityId &&
      metricDataType &&
      metric &&
      cadence
    ),
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
