import { useContext, useEffect, useMemo, useState } from 'react';
import { MapView } from '@odaia/shared-components/src/map/mapView';
import { MapViewContext } from '@odaia/shared-components/src/contexts/mapViewContext';
import { useSnackbar } from '@odaia/ui/src/components/snackbar';
import { MaptualListObjective } from '@odaia/domain/src/types';
import { FieldContext } from '../fieldContext';
import { useEntityCoordinates } from './hooks/useEntityCoordinates';
import { useEntityPrecallInsights } from './hooks/useEntityPrecallInsights';
import { useEntityRuleInsights } from './hooks/useEntityRuleInsights';
import { MapToggle } from './mapToggle';
import { RegionSelection } from '../navigation/regionSelection/RegionSelection';
import { EntityPopover } from './entityPopover';
import { EntityFullModal } from './entityFullModal';
import { AppContext } from '../../../appContext';
import useFeatureToggles from '../../../../../hooks/useFeatureToggles';
import { useIsAuthorized } from '../../../../../hooks/useIsAuthorized';
import { RBAC_PERMISSIONS } from '../../../../../constants';
import useRelevantUserSpecialties from '../../../../../hooks/useRelevantUserSpecialties';

export const MapViewProvider = () => {
  const [objective, setObjective] = useState<MaptualListObjective | null>(null);
  const [objectiveIndex, setObjectiveIndex] = useState<number | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { profile } = useContext(AppContext);
  const {
    project,
    maptualListMetadata,
    projectMaptualList: maptualList,
    isMaptualListLoading,
    isMaptualListsLoading,
    maptualListObjectives,
    setObjective: setFieldObjective,
    objective: fieldObjective,
  } = useContext(FieldContext);

  useEffect(() => {
    if (fieldObjective && fieldObjective.id) {
      setFieldObjective(null);
    }
  }, [fieldObjective]);

  const {
    data: entityCoordinatesData,
    isLoading: isLoadingEntityCoords,
    isError: isErrorEntityCoords,
  } = useEntityCoordinates({
    maptualListMetadata,
    project,
  });
  const productLineId = project?.metadata?.productLineId;
  const projectId = project?.metadata?.projectId;

  const {
    data: entityPrecallInsights,
    isLoading: isEntityPrecallInsightsLoading,
  } = useEntityPrecallInsights({
    maptualListMetadata,
    projectId,
    objectiveId: objective?.id,
  });

  const { data: entityRuleInsights, isLoading: isEntityRuleInsightsLoading } =
    useEntityRuleInsights({
      regionId: maptualListMetadata.sfMaptualListId,
      productLineId,
      objectiveId: objective?.id,
    });
  const featureToggles = useFeatureToggles();
  const canUserFilterMapView =
    featureToggles(profile.userGroup, 'mapViewFiltering') ||
    useIsAuthorized([RBAC_PERMISSIONS.MAP_VIEW_FILTERING]);
  const voiceEnabled =
    featureToggles(profile.userGroup, 'maptualVoiceAssistant') &&
    profile?.role?.type === 'ADMIN';
  const relevantUserSpecialties = useRelevantUserSpecialties();

  const productLineUserCreatedLists =
    profile?.userCreatedLists?.[productLineId];

  const anonymize = voiceEnabled;

  const mapContextValues = useMemo(
    () => ({
      projectId,
      productLineId,
      maptualListMetadata,
      maptualList,
      isMaptualListLoading,
      isMaptualListsLoading,
      maptualListObjectives,
      entityCoordinatesData,
      isLoadingEntityCoords,
      isErrorEntityCoords,
      objective,
      setObjective,
      objectiveIndex,
      setObjectiveIndex,
      entityPrecallInsights,
      isEntityPrecallInsightsLoading,
      entityRuleInsights: entityRuleInsights?.data,
      isEntityRuleInsightsLoading,
      enqueueSnackbar,
      canUserFilterMapView,
      relevantUserSpecialties,
      productLineUserCreatedLists,
      voiceEnabled,
      anonymize,
    }),
    [
      projectId,
      productLineId,
      maptualListMetadata,
      maptualList,
      isMaptualListLoading,
      isMaptualListsLoading,
      maptualListObjectives,
      entityCoordinatesData,
      isLoadingEntityCoords,
      isErrorEntityCoords,
      objective,
      objectiveIndex,
      entityPrecallInsights,
      isEntityPrecallInsightsLoading,
      entityRuleInsights,
      isEntityRuleInsightsLoading,
      enqueueSnackbar,
      canUserFilterMapView,
      relevantUserSpecialties,
      productLineUserCreatedLists,
      voiceEnabled,
      anonymize,
    ]
  );
  return (
    <MapViewContext.Provider value={mapContextValues}>
      <MapView
        listMapToggle={<MapToggle />}
        regionSelection={
          maptualListMetadata?.listName && (
            <RegionSelection
              regionName={
                anonymize
                  ? `Territory ${String(
                      maptualListMetadata?.sfMaptualListId
                    ).slice(-3)}`
                  : maptualListMetadata?.listName
              }
              anonymizeRegions={anonymize}
            />
          )
        }
        EntityPopover={EntityPopover}
        EntityFullModal={EntityFullModal}
        moveZoomControlsUp
      />
    </MapViewContext.Provider>
  );
};
