import { styled, Box } from '@mui/material';
import ChangeChip from './changeChip';

export const getScoreColours = (score, themeColors) => {
  if (score === null || score === undefined || score < 0) {
    return themeColors.powerscore["no-score"];
  }

  const roundedScore = Math.round(score);
  return themeColors.powerscore[roundedScore];
};

const PrimaryScore = styled(Box, {
  shouldForwardProp: (prop) => !['score', 'scoreFont'].includes(prop),
})(({ theme: { themeColors }, score, scoreFont }) => ({
  borderRadius: '50%',
  borderWidth: 3,
  borderStyle: 'solid',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: scoreFont === 'large' ? 22 : 18,
  paddingTop: 1,
  color: 'black',
  gridColumnStart: 1,
  letterSpacing: -0.5,
  gridRowStart: 1,
  ...getScoreColours(score, themeColors),
}));

const ObjectiveScore = styled(Box, {
  shouldForwardProp: (prop) => !['score', 'zIndex', 'selected', 'scoreFont'].includes(prop),
})(({ theme: { themeColors }, score, scoreFont,zIndex = 0 }) => ({
  width: scoreFont === 'large' ? 12 : 8,
  height: scoreFont === 'large' ? 12 : 8,
  borderRadius: '50%',
  borderWidth: 2,
  borderStyle: 'solid',
  alignItems: 'center',
  justifyContent: 'center',
  borderColor: themeColors.mainBackground,
  background: getScoreColours(score, themeColors).borderColor,
  marginLeft: -6,
  zIndex,
}));

const PrimaryScoreWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'scoreSize',
})(({ scoreSize }) => ({
  display: 'grid',
  gridTemplateRows: '100%',
  gridTemplateColumns: '100%',
  height: scoreSize,
  width: scoreSize,
}));

const ObjectiveScoresWrapper = styled(Box)(() => ({
  display: 'flex',
  gridColumnStart: 1,
  gridRowStart: 1,
  alignItems: 'end',
  justifyContent: 'end',
  marginBottom: -2,
  marginRight: -2,
}));

const ScoreWrapper = styled(Box)(() => ({
  width: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const PowerScore = ({
  score = 0,
  scoreChangeValue = 0,
  isSelected = false,
  objectiveScoreList = [],
  style = {},
  scoreSize = 40,
  isVeeva = false,
  scoreFont='large',
}) => {
  const showObjectiveScores = objectiveScoreList.length > 1 || isVeeva;

  return (
    <ScoreWrapper sx={style}>
      <PrimaryScoreWrapper scoreSize={scoreSize}>
        <PrimaryScore score={score} scoreFont={scoreFont}>
          <p data-testid="test-maptual-score">{score}</p>
        </PrimaryScore>
        <ObjectiveScoresWrapper>
          {showObjectiveScores &&
            objectiveScoreList.map((objectiveScore, i) => (
              <ObjectiveScore
                score={objectiveScore}
                zIndex={objectiveScoreList.length - i}
                key={`objective-${objectiveScore}-${i}`}
                selected={isSelected}
                scoreFont={scoreFont}
              />
            ))}
        </ObjectiveScoresWrapper>
      </PrimaryScoreWrapper>
      {scoreChangeValue !== 0 ? (
        <ChangeChip change={scoreChangeValue} selected={isSelected} />
      ) : null}
    </ScoreWrapper>
  );
};
