/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { baseAlbInstance, maptualApiInstance } from './config';
import { coreApi, fieldApi } from './maptualApiClient';
import { EntityType } from '../clients/maptualAPI';

export function getProjectList() {
  return maptualApiInstance.get(`core/project-list`, { timeout: 120000 });
}

export function getProject(projectId: string) {
  if (projectId) {
    return coreApi.getProjectById({ projectId });
  }
  return getProjectList();
}

export function getMetrics({ project, objective, entityType }) {
  return maptualApiInstance.get(
    `/pulse/projects/${project}/objectives/${objective}/metrics?entity_type=${entityType}`,
    { timeout: 60000 }
  );
}

export function getSegments(projectId) {
  return maptualApiInstance.get(
    `/sphere/product-lines/${null}/projects/${projectId}/segments`,
    { timeout: 120000 }
  );
}

export function clearCache(projectId, clearCacheFlag) {
  if (projectId) {
    return clearCacheWithProjectId(projectId, clearCacheFlag);
  }
  return clearCacheForAllProjects(clearCacheFlag);
}

const clearCacheWithProjectId = (projectId, clearCacheFlag) => {
  const params = { clear_cache_flag: clearCacheFlag };
  return maptualApiInstance.get(`admin/clearcache/${projectId}`, {
    params,
    timeout: 180000,
  });
};

const clearCacheForAllProjects = (clearCacheFlag) => {
  const params = { clear_cache_flag: clearCacheFlag };
  return maptualApiInstance.get(`admin/clearcache`, {
    params,
    timeout: 180000,
  });
};

export async function patchProjectMetadata(metadata) {
  return baseAlbInstance.patch(`projects`, { metadata });
}

export function getDashboardStructure({ projectId, segmentId, maptualListId }) {
  return maptualApiInstance.get(
    `sphere/product-lines/TODO/projects/${projectId}/regions/${maptualListId}/segments/${segmentId}/dashboard_charts`,
    { timeout: 60000 }
  );
}

export function getDashboardSubUrl({
  subUrl,
  projectId,
  segmentId,
  maptualListId,
  signal,
}) {
  const projectQueryParam = projectId ? `?projectId=${projectId}` : '';
  const segmentQueryParam = segmentId ? `&segmentId=${segmentId}` : '';
  const maptualListQueryParam = maptualListId
    ? `&maptualListId=${maptualListId}`
    : '';

  if (subUrl.includes('/attributionAssembler/')) {
    const params = { attribution_type: subUrl.split('/')[2] };
    return maptualApiInstance.get(
      `sphere/product-lines/TODO/projects/${projectId}/regions/${maptualListId}/segments/${segmentId}/attribution`,
      { params, timeout: 300000 }
    );
  }

  const url = `${subUrl}${projectQueryParam}${maptualListQueryParam}${segmentQueryParam}`;
  return baseAlbInstance.get(url, { timeout: 300000, signal });
}

export function getTableResponse({
  productLineId,
  projectId,
  segmentId,
  maptualListId,
  objectiveId,
  signal,
}) {
  return maptualApiInstance.get(
    `/sphere/hcp-view/product-lines/${productLineId}/projects/${projectId}/objectives/${objectiveId}/regions/${maptualListId}/segments/${segmentId}`,
    { timeout: 120000, signal }
  );
}

export function deleteProject(projectId) {
  return baseAlbInstance.delete(`projects/${projectId}`);
}

export function getAnalysis(analysisId, userId = null, href = null) {
  const userIdQueryParam = userId ? `?userId=${userId}` : '';
  const analysisUrl = `analysis${
    analysisId ? `/${analysisId}${userIdQueryParam}` : ''
  }`;
  const url = !analysisId && href ? href : analysisUrl;

  return baseAlbInstance.get(url, { timeout: 30000 });
}

export function getCuratedList({ projectId, regionId }) {
  return maptualApiInstance
    .get(`field/maptual-list/curated`, {
      params: {
        project_id: projectId,
        region_id: regionId,
      },
      timeout: 600000,
    })
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getLastestCurationReport({ projectId }) {
  if (!projectId) {
    return Promise.reject();
  }
  return baseAlbInstance
    .get(
      `curationDashboard/reports/projectId/${projectId}?type=curationReport`,
      {
        timeout: 600000,
      }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getCurationReport({
  projectId,
  regionId,
  report,
  graphType,
  aggregationMethod,
}) {
  if (!projectId || !regionId || !report) {
    return Promise.reject();
  }
  return baseAlbInstance
    .get(
      `curationDashboard/reports/projectId/${projectId}/regionId/${regionId}?type=${report}${
        graphType ? `&graphType=${graphType}` : ''
      }${aggregationMethod ? `&aggregationMethod=${aggregationMethod}` : ''}`,
      {
        timeout: 600000,
      }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getCurationEvents({
  productLineId,
  projectId,
  mode,
  timestamp,
}) {
  if (!productLineId || !projectId || !mode) {
    return Promise.reject();
  }
  return maptualApiInstance.get(
    `admin/product-lines/${productLineId}/projects/${projectId}/curation/events/latest?mode=${mode}${
      timestamp ? `&timestamp=${timestamp}` : ''
    }`,
    {
      timeout: 600000,
    }
  );
}

export function startCurationExecution({ projectId, mode }) {
  return baseAlbInstance.post(
    `curationDashboard`,
    { projectId, mode },
    { timeout: 30000 }
  );
}

export function rollbackCuration({ marketId, productLineId, mode = 'live' }) {
  return maptualApiInstance.post(
    `admin/markets/${marketId}/product-lines/${productLineId}/curation/rollback?mode=${mode}`,
    { marketId },
    { timeout: 60000 }
  );
}

export function getMaptualList(projectId, maptualListId, objectiveId) {
  let url = '';
  let params = {};

  if (maptualListId && objectiveId) {
    url = `field/maptual-list`;
    params = { region_id: maptualListId, objective_id: objectiveId };
  } else if (maptualListId) {
    url = `field/maptual-list`;
    params = { region_id: maptualListId };
  }

  return maptualApiInstance
    .get(url, {
      params: {
        project_id: projectId,
        ...params,
      },
      timeout: 600000,
    })
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getMaptualListsMetadata(projectId) {
  return maptualApiInstance
    .get(`field/maptual-list/metadata`, {
      params: { project_id: projectId },
      timeout: 600000,
    })
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 600000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getInsights(entityId, projectId, regionId) {
  return baseAlbInstance
    .get(
      `insights/project/${projectId}/region/${regionId}/entity/${entityId}`,
      {
        timeout: 60000,
      }
    )
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 60000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });
}

export function getEntityPrecallInsights({
  entityId,
  regionId,
  projectId,
  objectiveId,
}: {
  entityId: string;
  regionId: string;
  projectId: string;
  objectiveId: string;
}) {
  return fieldApi.getEntityPrecallInsights({
    projectId,
    regionId,
    objectiveId,
    entityId,
  });
}

export function getEntitiesPrecallInsights({
  regionId,
  projectId,
  objectiveId,
  entityType,
}: {
  regionId: string;
  projectId: string;
  objectiveId: string;
  entityType: EntityType;
}) {
  return fieldApi.getEntitiesPrecallInsights({
    projectId,
    regionId,
    objectiveId,
    entityType,
  });
}

export function getEntityMessages({ entityId, productLineId, objectiveId }) {
  return maptualApiInstance.get(`field/entity/messages`, {
    params: {
      product_line_id: productLineId,
      objective_id: objectiveId,
      entity_id: entityId,
    },
    timeout: 90000,
  });
}

export function getEntityActivityInsights({
  projectId,
  maptualListId,
  entityId,
}) {
  return maptualApiInstance.get(
    `core/projects/${projectId}/activity-insights/regions/${maptualListId}/entities/${entityId}`,
    {
      timeout: 90000,
    }
  );
}

export function getMaptualListURL({ projectId, maptualListId }) {
  return maptualApiInstance
    .get(`field/maptual-list/csv-export`, {
      params: {
        project_id: projectId,
        region_id: maptualListId,
      },
      timeout: 60000,
    })
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          return error.response.data
            ? Promise.resolve({ data: error.response.data })
            : Promise.reject(error);
        }
        default: {
          return Promise.reject(error);
        }
      }
    });
}

export async function getGskCsv({ projectId }) {
  try {
    const response = await maptualApiInstance.get('csv-deployments', {
      params: {
        project_id: projectId,
      },
      timeout: 60000,
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deployGskCsv({ projectId, path, filename, newFilename }) {
  try {
    const response = await maptualApiInstance.post(
      'csv-deployments',
      {
        project_id: projectId,
        s3_path: path,
        filename,
        new_filename: newFilename,
      },
      { timeout: 0 } // No timeout
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export function getProjectObjectives({ projectId }) {
  return maptualApiInstance.get<Record<string, unknown>[]>(
    `core/projects/${projectId}/objectives`,
    {
      timeout: 15000,
    }
  );
}

export function getObjectives({ projectId }) {
  return maptualApiInstance.get(`core/projects/${projectId}/objectives`, {
    timeout: 15000,
  });
}

export const getDetailedProjectStatus = (projectId) =>
  baseAlbInstance
    .get(`/projects/${projectId}/detailedProjectStatus`, {
      timeout: 60000,
    })
    .catch((error) => {
      switch (error?.response?.status) {
        case 307: {
          if (error.response.data) {
            return axios.get(error.response.data, { timeout: 60000 });
          }
          return Promise.reject(error);
        }
        default:
          return Promise.reject(error);
      }
    });

export const getTestSuiteStatus = () =>
  maptualApiInstance.get(
    `admin/product-lines/todo/projects/quality-report-checks`,
    {
      timeout: 60000,
    }
  );

export const getProjectTestSuiteDetailedStatuses = (projectId) =>
  maptualApiInstance.get(
    `admin/product-lines/todo/projects/${projectId}/project-quality-checks`,
    {
      timeout: 60000,
    }
  );

export const getUserGroupTestSuiteDetailedStatuses = () =>
  maptualApiInstance.get(
    `admin/product-lines/todo/projects/user-group-quality-checks`,
    {
      timeout: 60000,
    }
  );

export const getUserGroupDataReport = () =>
  maptualApiInstance.get(
    `admin/product-lines/todo/projects/user-group-report`,
    {
      timeout: 60000,
    }
  );

export const getRecentProjects = (userGroup, businessUnit) =>
  maptualApiInstance.get('csv-delivery-dashboard/recent-projects', {
    params: { user_group: userGroup, business_unit: businessUnit },
    timeout: 60000,
  });

export const getObjectiveBaskets = ({
  projectId,
  entityType,
}: {
  projectId: string;
  entityType: EntityType;
}) => coreApi.getProjectObjectiveBaskets({ projectId, entityType });
