import {
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { METRIC_DATA_TYPE, METRIC_UNIT } from '../../constants';
import {
  useMarketPerformanceMetadata,
  MetricData,
  Cadence,
} from './useMarketPerformanceMetadata';
import { AppContext } from '../../../../../containers/application/appContext';
import { hasMetricDataType } from '../../helpers';
import {
  EntityType,
  ChartMetadataResponse,
} from '../../../../../clients/maptualAPI';

export interface MarketPerformanceMetadataContextType {
  productLineId: string;
  regionId: string;
  projectId: string;
  objectiveId: string;
  entityId: string;
  entityType: EntityType;
  metadata: ChartMetadataResponse;
  currentTabMetadata: MetricData;
  isMetadataLoading: boolean;
  isMetadataError: boolean;
  refetchMetadata: () => void;
  selectedCardTab: METRIC_DATA_TYPE;
  setSelectedCardTab: Dispatch<SetStateAction<METRIC_DATA_TYPE>>;
  selectedMetric: string;
  setSelectedMetric: Dispatch<SetStateAction<string>>;
  selectedCadence: Cadence;
  setSelectedCadence: Dispatch<SetStateAction<Cadence>>;
  selectedUnit: METRIC_UNIT;
  setSelectedUnit: Dispatch<SetStateAction<METRIC_UNIT>>;
  isMetadataIdle: boolean;
}

export const useMarketPerformanceMetadataContext = (
  projectId: string,
  regionId: string,
  objectiveId: string,
  entityId: string,
  entityType: EntityType
): MarketPerformanceMetadataContextType => {
  const { projectList } = useContext(AppContext);
  const { productLineId } =
    projectList?.find((proj) => proj.projectId === projectId) || {};

  const [selectedCardTab, setSelectedCardTab] = useState<METRIC_DATA_TYPE>(
    METRIC_DATA_TYPE.VOLUME
  );
  const [currentTabMetadata, setCurrentTabMetadata] =
    useState<MetricData | null>(null);
  const [selectedMetric, setSelectedMetric] = useState<string>();
  const [selectedCadence, setSelectedCadence] = useState<Cadence>();
  const [selectedUnit, setSelectedUnit] = useState<METRIC_UNIT>();

  const {
    isLoading: isMetadataLoading,
    data: metadata,
    refetch: refetchMetadata,
    isError: isMetadataError,
    isIdle: isMetadataIdle,
  } = useMarketPerformanceMetadata(objectiveId, entityType);

  useEffect(() => {
    const isTabEnabled = hasMetricDataType(
      metadata?.metricDataTypes,
      selectedCardTab
    );
    if (!isTabEnabled) {
      setSelectedCardTab(METRIC_DATA_TYPE.VOLUME);
    }
  }, [objectiveId, selectedCardTab]);

  useEffect(() => {
    if (metadata && metadata.metricDataTypes) {
      const currentTabMetadata = metadata.metricDataTypes.find(
        (dataType) => dataType.metricDataType === selectedCardTab
      );

      setCurrentTabMetadata(currentTabMetadata);

      if (currentTabMetadata.metrics?.length > 0) {
        setSelectedMetric(currentTabMetadata.metrics[0].rxType);
        setSelectedUnit(currentTabMetadata.metrics[0].unit);

        if (currentTabMetadata.metrics[0].cadences?.length > 0) {
          setSelectedCadence(currentTabMetadata.metrics[0].cadences[0]);
        }
      }
    }
  }, [metadata, selectedCardTab]);

  useEffect(() => {
    if (selectedMetric) {
      const isCadenceAvailable = currentTabMetadata?.metrics?.find(
        (metric) =>
          metric.rxType === selectedMetric &&
          metric.cadences.some((c) => c.id === selectedCadence?.id)
      );
      if (!isCadenceAvailable) {
        setSelectedCadence(
          currentTabMetadata?.metrics?.find(
            (metric) => metric.rxType === selectedMetric
          )?.cadences[0]
        );
      }
    }
  }, [selectedMetric]);

  return {
    productLineId,
    regionId,
    projectId,
    objectiveId,
    entityId,
    entityType,
    metadata,
    currentTabMetadata,
    isMetadataLoading,
    isMetadataError,
    refetchMetadata,
    selectedCardTab,
    setSelectedCardTab,
    selectedMetric,
    setSelectedMetric,
    selectedCadence,
    setSelectedCadence,
    selectedUnit,
    setSelectedUnit,
    isMetadataIdle,
  };
};
