import { Button, Typography, Box, styled } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { TableHeader } from './tableHeader';
import { FetchContainer } from '../fetchContainer';
import {  RefObject } from 'react';

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullHeight',
})(({ theme: { themeColors }, fullHeight }) => ({
  width: '100%',
  overflowY: 'auto',
  backgroundColor: themeColors.cardBackgroundColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  borderRadius: '6px',
  height: fullHeight ? '100%' : 'auto',
  colorScheme: themeColors.colorScheme,
  display: 'grid',
  gridTemplateRows: 'min-content minmax(0, 1fr)'
}));

const StyledButton = styled(Button)(({ theme: { themeColors } }) => ({
  width: 'fit-content',
  padding: '8px 12px',
  minHeight: '36px',
  color: themeColors.buttonContentColor,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px',
  backgroundColor: themeColors.buttonBackgroundColor,
  borderRadius: 4,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: themeColors.buttonBorderColor,
  },
  '& .MuiSvgIcon-root': {
    color: themeColors.buttonContentColor,
  },
}));

const MessageTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  fontSize: 14,
  fontWeight: 500,
  marginBottom: 8,
}));

const MessageSubtitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.emptyText,
  opacity: 0.8,
  fontSize: 12,
  fontWeight: 400,
  marginBottom: 16,
}));

const MessageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showBorder',
})(({ theme: { themeColors }, showBorder }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '36px 0px',
  borderRadius: 10,
  border: showBorder
    ? `1px solid ${themeColors.contentCardBorderColor}`
    : 'none',
  background: themeColors.contentCardBackgroundColor,
}));

const StyledProgress = styled(CircularProgress)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.buttonContentColor,
  })
);

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'contentLoaded',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
}));

type EntitiesTableProps = {
  tableRef?: RefObject<HTMLDivElement>,
  rows: { [regionId: string]: unknown[]};
  regions: unknown[];
  regionId: string;
  regionName?: string;
  isLoading: boolean;
  shouldShowError: boolean;
  objectiveId: string;
  cadence: string;
  metric?: string;
  metricType?: string;
  timestamp: string;
  activitySubtitle: string;
  activityCadence: string;
  projectTargetProducts: unknown[];
  showAverageRow?: boolean;
  stickyColumns: unknown[];
  nonStickyColumns: unknown[];
  metricColumns: unknown[];
  activityColumns: unknown[];
  isFetching: boolean;
  fullHeight: boolean;
  refetch: () => void;
  onRowClick: (entityId: string) => void;
  RowContentComponent:  (props: {
    objectiveId: string;
    cadence: string;
    row: unknown;
    rank: unknown;
    isLoading: boolean;
    onRowClick: (entityId: string) => void;
}) => JSX.Element
}

export const EntitiesTable = ({
  tableRef,
  rows,
  regions,
  regionId,
  regionName,
  objectiveId,
  cadence,
  isLoading,
  shouldShowError,
  metric,
  metricType,
  timestamp,
  activitySubtitle,
  activityCadence,
  projectTargetProducts,
  showAverageRow,
  stickyColumns,
  nonStickyColumns,
  metricColumns,
  activityColumns,
  isFetching,
  fullHeight = false,
  refetch,
  RowContentComponent,
  onRowClick = () => null,
}: EntitiesTableProps) => {


  const errorContainer = () => (
    <MessageContainer showBorder>
      <MessageTitle>There was an issue loading the content</MessageTitle>
      <MessageSubtitle>
        Try to refresh the content. If the issue still persists contact support.
      </MessageSubtitle>
      <StyledButton
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={() => refetch()}
      >
        Try again
      </StyledButton>
  </MessageContainer>
  );

  const loadingContainer = () => (
    <MessageContainer showBorder>
      <MessageTitle>Retrieving data...</MessageTitle>
      <StyledProgress />
    </MessageContainer>
  );
  if (isLoading) return loadingContainer();
  if (shouldShowError)
    return errorContainer();

  const districtRow = {
    id: regionId,
    score: null,
    rep: {
      id: regionId,
      name: 'DISTRICT AVERAGE',
    },
    entity: {
      id: regionId,
      name: regions?.find((region) => region.entity_id == regionId)?.listName,
    },
  };

  const currentRows = rows[regionId] || [];

  const AverageRow =
    showAverageRow && currentRows && currentRows.length > 0 ? (
      <RowContentComponent
        objectiveId={objectiveId}
        cadence={cadence}
        metric={metric}
        metricType={metricType}
        row={districtRow}
        isDistrict
        timestamp={timestamp}
        isLoading={isLoading}
      />
    ) : null;
  return (
    <>
      <Wrapper ref={tableRef} fullHeight={fullHeight}>
        {currentRows && currentRows.length > 0 && (
          <TableHeader
            objectiveId={objectiveId}
            stickyColumns={stickyColumns}
            nonStickyColumns={nonStickyColumns}
            metricColumns={metricColumns}
            activityColumns={activityColumns}
            metric={metric}
            metricCadence={cadence}
            activitySubtitle={activitySubtitle}
            activityCadence={activityCadence}
            latestCurationTimestamp={timestamp}
            projectTargetProducts={projectTargetProducts}
          />
        )}

        <Container contentLoaded={currentRows && currentRows.length > 0} className='scrollable'>
          {AverageRow}
          {currentRows.map((row, index) => (
            <RowContentComponent
              rank={index + 1}
              key={row.id}
              objectiveId={objectiveId}
              cadence={cadence}
              metric={metric}
              metricType={metricType}
              row={row}
              timestamp={timestamp}
              isLoading={isLoading}
              regionName={regionName}
              onRowClick={onRowClick}
            />
          ))}
          {isFetching && <FetchContainer />}
        </Container>
      </Wrapper>

      {shouldShowError && errorContainer()}
    </>
  );
};
