/* eslint-disable no-restricted-syntax */
import { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RegionSelectionModal } from '../../../shared/RegionSelectionModal';
import { AppContext } from '../../../../appContext';
import { FieldContext } from '../../fieldContext';
import { ROUTES } from '../../../../../../constants';
import { selectEntity } from '../../../../../../actions/projectViewActions';

const getHierarchalRegionList = (
  regionsAgg,
  hierarchy,
  depth,
  anonymizeRegions = false
) => {
  // base case
  if (!hierarchy || hierarchy.length === 0) return;

  if (hierarchy.metadata?.listName !== 'Provincial') {
    regionsAgg.push({
      ...{
        ...hierarchy.metadata,
        listName: anonymizeRegions
          ? `Territory ${String(hierarchy.metadata?.sfMaptualListId).slice(-3)}`
          : hierarchy.metadata.listName,
      },
      depth,
    });
  }

  hierarchy.nodes.sort((a, b) =>
    a.metadata.listName === 'National'
      ? 1
      : a.metadata.listName.localeCompare(b.metadata.listName)
  );
  const nextDepth = depth + 1;
  for (const node of hierarchy.nodes) {
    getHierarchalRegionList(regionsAgg, node, nextDepth, anonymizeRegions);
  }
};

export const getRegionList = (
  maptualListHierarchy,
  anonymizeRegions = false
) => {
  const hierarchyRegionList = [];
  getHierarchalRegionList(
    hierarchyRegionList,
    maptualListHierarchy,
    0,
    anonymizeRegions
  );
  return hierarchyRegionList;
};

const getAssignedRegions = (hierarchalList, assignedRegions) => {
  const hierarchalListReversed = [];
  hierarchalListReversed.push(...hierarchalList);
  hierarchalListReversed.reverse();
  let regionInParentDistrict = false;
  let needsParents = false;
  const assignedList = [];
  for (const region of hierarchalListReversed) {
    if (region.locality.includes('DISTRICT')) {
      needsParents = false;
      if (regionInParentDistrict) {
        assignedList.push(region);
        // eslint-disable-next-line no-continue
        continue;
      }
    }

    if (!needsParents) {
      regionInParentDistrict = false;
    }
    if (assignedRegions.includes(region.listName)) {
      regionInParentDistrict = true;
      needsParents = true;
      assignedList.push(region);
      // eslint-disable-next-line no-continue
      continue;
    }
  }

  return assignedList.reverse();
};

export const RegionModalProvider = ({
  isOpen,
  handleClose,
  anonymizeRegions = false,
}) => {
  const { project, maptualListMetadata, maptualListHierarchy } =
    useContext(FieldContext);
  const { profile } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [hierarchalRegions, setHierarchalRegions] = useState([]);
  const [hierarchalAssignedRegions, setHierarchalAssignedRegions] = useState(
    []
  );

  useEffect(() => {
    if (!maptualListHierarchy) return;
    const hierarchyRegionList = getRegionList(
      maptualListHierarchy,
      anonymizeRegions
    );
    setHierarchalRegions(hierarchyRegionList);
    setHierarchalAssignedRegions(
      getAssignedRegions(hierarchyRegionList, profile?.role?.territoryIds || [])
    );
  }, [maptualListHierarchy]);

  const handleRegionSelection = (region) => {
    if (region.maptualListId === maptualListMetadata.maptualListId) {
      handleClose();
    }
    const view = location.pathname.split('/').slice(6).join('/');
    const route = `${ROUTES.FIELD}${ROUTES.PROJECTS}/${project?.metadata?.projectId}/maptualListId/${region.maptualListId}/${view}`;
    history.push(route);
    dispatch(selectEntity(null));

    handleClose();
  };

  return (
    <RegionSelectionModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleRegionSelection={handleRegionSelection}
      regionsForSearch={hierarchalRegions}
      regionList={hierarchalAssignedRegions}
    />
  );
};
