import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PersonOutlineOutlined, Star } from '@mui/icons-material';
import {
  styled,
  ButtonGroup,
  CircularProgress,
  Drawer,
  Button,
  Typography,
  Box,
  Popover,
  Badge,
  ThemeOptions,
  Skeleton,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useParams, useLocation } from 'react-router-dom';
import { ThemeColors } from '@odaia/ui/src/fieldViewStyles';
import { EntityJourney } from './entityJourney';
import { trackJourneySelection } from '../../trackers/appEventTracker';
import { useToggleHcpInList } from '../../hooks/useToggleHcpInList';
import ListMembershipSelection from './listMembershipSelection';
import { EntityTitleBarContext } from './entityTitleBarContext';
import { AppContext } from '../../containers/application/appContext';
import { useUserCreatedLists } from '../../containers/fieldview/useUserCreatedLists';
import { PowerScorePopover } from './PowerscorePopover';
import { FieldContext } from '../../containers/application/appViews/field/fieldContext';
import { useShowOverallPowerscore } from '../../hooks/useShowOverallPowerscore';

const TitleBar = styled(Box)(({ theme: { themeColors } }) => ({
  width: '100%',
  height: 100,
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderColor: themeColors.contentCardBorderColor,
  maxWidth: 750,
  alignItems: 'flex-end',
  paddingBottom: 10,
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 16,
}));

const ColumnContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOverallPowerscoreVisible',
})(({ isOverallPowerscoreVisible }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: isOverallPowerscoreVisible ? 11.5 : 0,
  gap: 5,
}));

const HorizontalContainer = styled(Box)(() => ({
  flexGrow: 2,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  minHeight: 33,
}));

const InverseStar = styled(Star)(({ theme: { themeColors } }) => ({
  fill: themeColors.maptualListInverseIconColor,
  fontSize: 16,
}));
const SecondaryButton = styled(Button)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.buttonSecondaryBackgroundColour,
  padding: 0,
  width: '47px',
  height: '36px',
}));
const SecondaryDropdownButton = styled(SecondaryButton)(
  ({ theme: { themeColors } }) => ({
    backgroundColor: themeColors.buttonSecondaryBackgroundColour,
    padding: 0,
    width: '42px',
    height: '36px',
  })
);
const SmallIconButton = styled(Button)(() => ({
  height: '36px',
  '.MuiSvgIcon-root': {
    fontSize: 16,
  },
}));
const ButtonContainer = styled(Box)(() => ({ display: 'flex', gap: '10px' }));
const StyledPopover = styled(Popover)(() => ({
  marginTop: 9,
}));
const StyledBadge = styled(Badge)(({ theme: { themeColors } }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: themeColors.notificationBadgeSurface,
    color: themeColors.notificationBadgeContent,
  },
}));
const StyledButtonGroup = styled(ButtonGroup)(() => ({
  width: 121,
  justifyContent: 'flex-end',
}));
const InverseDropdownIcon = styled(ArrowDropDownIcon)(
  ({ theme: { themeColors } }) => ({
    fill: themeColors.maptualListInverseIconColor,
  })
);
const DropdownButton = styled(Button)(() => ({
  width: 42,
}));

const TitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const SkeletonLoadingBar = styled(Skeleton)(
  ({ theme: { themeColors, spacing } }) => ({
    backgroundColor: themeColors.surfaceEmpty,
    transform: 'scale(1)',
    marginBottom: spacing(2),
    height: 100,
    width: '100%',
  })
);

const StyledTitleBadge = styled(Box)(({ theme: { themeColors, spacing } }) => ({
  padding: '2px 4px',
  display: 'flex',
  borderRadius: 3,
  alignSelf: 'center',
  gap: 4,
  backgroundColor: themeColors.badgePrimaryColor,
  height: 18,
  marginLeft: spacing(2),
}));

const StyledBadgeTypography = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    lineHeight: '14px',
    color: themeColors.badgePrimaryContentColor,
  })
);

export function EntityTitleBarContainer({
  entity,
  isEntityDetailsLoading,
  maptualScoreNormalized,
  displayUserCreatedListsButton,
  objectiveScores,
  maptualListId,
  objectiveId,
  isAmaOptOut,
}) {
  const { profile, projectList } = useContext(AppContext);
  const fieldContext = useContext(FieldContext);
  const { projectId } = useParams();

  const project = projectList.find((proj) => proj.projectId === projectId);
  const projectProductLineId = project?.productLineId;
  const locality = fieldContext?.maptualListMetadata?.locality || 'N/A';

  const hcpId = entity?.metadata?.entityId;
  const {
    isCreatedListsLoading,
    isCreatedListsError,
    userCreatedLists,
    createdListsError,
  } = useUserCreatedLists({
    userId: profile?.userId,
  });

  const [userLists, setUserLists] = useState({
    starred: false,
    noSee: false,
    longTermLeave: false,
  });

  const [isStarButtonBusy, setIsStarButtonBusy] = useState(
    isCreatedListsLoading
  );

  useEffect(() => {
    if (!isCreatedListsLoading && !isCreatedListsError) {
      const starredList = userCreatedLists[projectProductLineId]?.starred || [];
      const noSeeList = userCreatedLists[projectProductLineId]?.noSee || [];
      const longTermLeaveList =
        userCreatedLists[projectProductLineId]?.longTermLeave || [];

      setUserLists({
        ...userCreatedLists,
        starred: starredList.includes(hcpId),
        noSee: noSeeList.includes(hcpId),
        longTermLeave: longTermLeaveList.includes(hcpId),
      });

      setIsStarButtonBusy(false);
    }

    if (createdListsError) {
      // eslint-disable-next-line no-console
      console.error('[ERROR] Fetching User Created Lists', createdListsError);
    }
  }, [hcpId, projectProductLineId, userCreatedLists, isCreatedListsLoading]);

  const entityTitleBarContextValues = useMemo(
    () => ({
      userLists,
      project,
      locality,
    }),
    [userLists, project, locality]
  );

  return (
    <EntityTitleBarContext.Provider value={entityTitleBarContextValues}>
      <EntityTitleBar
        entity={entity}
        isEntityDetailsLoading={isEntityDetailsLoading}
        maptualScoreNormalized={maptualScoreNormalized}
        isStarButtonBusy={isStarButtonBusy}
        setIsStarButtonBusy={setIsStarButtonBusy}
        displayUserCreatedListsButton={displayUserCreatedListsButton}
        objectiveScores={objectiveScores}
        projectId={projectId}
        maptualListId={maptualListId}
        objectiveId={objectiveId}
        isAmaOptOut={isAmaOptOut}
      />
    </EntityTitleBarContext.Provider>
  );
}

const InverseStarButton = ({ toggleHcpStar }) => (
  <SecondaryButton
    aria-label="InverseStarButton"
    variant="outlined"
    onClick={toggleHcpStar}
  >
    <InverseStar />
  </SecondaryButton>
);

const OutlinedStarButton = ({ toggleHcpStar }) => (
  <SmallIconButton
    variant="outlined"
    onClick={toggleHcpStar}
    startIcon={<Star />}
  >
    Star
  </SmallIconButton>
);

const StarButton = ({ hcpId, isStarButtonBusy, setIsStarButtonBusy }) => {
  if (isStarButtonBusy) {
    return (
      <SmallIconButton variant="outlined">
        <CircularProgress
          data-testid="circular-progress-list-button"
          color="inherit"
          size="16px"
        />
      </SmallIconButton>
    );
  }

  const updateUserCreatedList = useToggleHcpInList(
    hcpId,
    'starred',
    'Primary Button'
  );

  const toggleHcpStar = () => {
    setIsStarButtonBusy(true);

    try {
      updateUserCreatedList();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('[ERROR] toggleHcpStar failed', {
        error,
        hcpId,
      });
    }
  };

  const {
    userLists: { starred },
  } = useContext(EntityTitleBarContext);

  if (starred) {
    return <InverseStarButton toggleHcpStar={toggleHcpStar} />;
  }

  return <OutlinedStarButton toggleHcpStar={toggleHcpStar} />;
};

export function EntityTitleBar({
  entity,
  isEntityDetailsLoading,
  maptualScoreNormalized,
  isStarButtonBusy,
  setIsStarButtonBusy,
  displayUserCreatedListsButton,
  objectiveScores,
  objectiveId,
  projectId,
  maptualListId,
  isAmaOptOut,
}) {
  const { userLists, project, locality } = useContext(EntityTitleBarContext);
  const { profile } = useContext(AppContext);
  const urlLocation = useLocation();

  const isOverallPowerscoreVisible = useShowOverallPowerscore(
    profile?.userGroup,
    project?.marketId
  );

  const theme: ThemeOptions = useTheme();
  // eslint-disable-next-line prefer-destructuring
  const themeColors: ThemeColors = theme.themeColors;

  const metadata = entity?.metadata;
  const entityUData = entity?.entityUData;
  const location = entityUData?.location || {};

  const {
    address = null,
    cityTown = '',
    stateProvShort = '',
    locationName = null,
  } = location;

  const [showDrawer, setShowDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonGroupRef = useRef();

  const openListSelection = () => {
    setAnchorEl(buttonGroupRef.current);
  };

  const closeListSelection = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;

  const toggleDrawer = (value) => {
    if (value !== showDrawer) setShowDrawer(value);
  };

  const activeListCount = Object.values(userLists).filter(
    (list) => list === true
  ).length;

  const anchorDrawerToContainerProps = {
    PaperProps: { style: { position: 'absolute', textAlign: 'left' } },
    BackdropProps: { style: { position: 'absolute' } },
    ModalProps: {
      container: document.getElementById('main-content'),
      style: {
        position: 'absolute',
      },
      slotProps: {
        backdrop: {
          style: {
            position: 'fixed',
          },
        },
      },
      keepMounted: true,
    },
  };

  const isTitleLoading = isEntityDetailsLoading || !metadata;
  const showConnectButton =
    urlLocation.pathname.split('/').slice(-1)[0] !== 'map';

  return isTitleLoading ? (
    <SkeletonLoadingBar />
  ) : (
    <TitleBar>
      <TitleWrapper>
        <ColumnContainer
          isOverallPowerscoreVisible={isOverallPowerscoreVisible}
          className="intercom-field-entity-title-bar-power-score"
        >
          {isOverallPowerscoreVisible &&
            maptualScoreNormalized === undefined &&
            '--'}

          {isOverallPowerscoreVisible && maptualScoreNormalized >= 0 ? (
            <PowerScorePopover
              powerscore={maptualScoreNormalized}
              objectiveScores={objectiveScores}
              locality={locality}
              regionId={maptualListId}
              objectiveId={objectiveId}
              entityType={entity?.metadata?.entityType}
              entityId={entity?.metadata?.entityId}
              entityName={entity?.metadata?.entityName}
              isNonDld={entity?.metadata?.isNonDld}
              isAmaOptOut={isAmaOptOut}
            />
          ) : null}
        </ColumnContainer>

        <ColumnContainer
          isOverallPowerscoreVisible={isOverallPowerscoreVisible}
          className="intercom-field-entity-title-bar-entity-info"
        >
          <Box display="flex" flexDirection="row">
            <Typography
              noWrap
              variant="h3"
              className="test-hcp-info-name"
              align="left"
            >
              {`${metadata.entityName}`}
            </Typography>

            {isAmaOptOut && (
              <StyledTitleBadge>
                <Tooltip
                  arrow
                  title={
                    <Typography variant="body2" width="208px">
                      This HCP has opted out of having individual prescribing
                      data released to pharmaceutical representatives, through
                      the American Medical Association (AMA).
                    </Typography>
                  }
                >
                  <StyledBadgeTypography variant="labelXs">
                    AMA Opt Out
                  </StyledBadgeTypography>
                </Tooltip>
              </StyledTitleBadge>
            )}
          </Box>

          <Typography
            noWrap
            variant="body2"
            className="test-hcp-info-address"
            style={{
              maxWidth: 300,
              color: themeColors.primaryMaptualListFilteringColor,
            }}
            align="left"
          >
            {address ||
              (locationName
                ? `${locationName}, ${cityTown}, ${stateProvShort}`
                : `${cityTown}, ${stateProvShort}`)}
          </Typography>
        </ColumnContainer>
      </TitleWrapper>

      <HorizontalContainer>
        <ButtonContainer>
          {displayUserCreatedListsButton ? (
            <>
              <StyledButtonGroup
                variant="outlined"
                ref={buttonGroupRef}
                className="intercom-field-user-created-lists-button"
              >
                <StarButton
                  hcpId={metadata?.entityId}
                  isStarButtonBusy={isStarButtonBusy}
                  setIsStarButtonBusy={setIsStarButtonBusy}
                />

                {activeListCount > 0 ? (
                  <StyledBadge
                    badgeContent={activeListCount}
                    data-testid="ListMembershipBadge"
                  >
                    <SecondaryDropdownButton onClick={openListSelection}>
                      <InverseDropdownIcon data-testid="InverseArrowDropDownButton" />
                    </SecondaryDropdownButton>
                  </StyledBadge>
                ) : (
                  <DropdownButton onClick={openListSelection}>
                    <ArrowDropDownIcon data-testid="ArrowDropDownButton" />
                  </DropdownButton>
                )}
              </StyledButtonGroup>
              <StyledPopover
                id={popoverId}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={closeListSelection}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { width: '262px' },
                }}
              >
                <ListMembershipSelection hcpId={metadata?.entityId} />
              </StyledPopover>
            </>
          ) : null}

          {showConnectButton && (
            <SmallIconButton
              className="intercom-field-connect-button"
              data-testid="test-hcp-profile"
              aria-label="expand"
              style={{
                marginRight: 0,
              }}
              variant="outlined"
              startIcon={<PersonOutlineOutlined />}
              onClick={() => {
                toggleDrawer(true);
                trackJourneySelection(metadata.entityName);
              }}
            >
              Connect
            </SmallIconButton>
          )}
        </ButtonContainer>
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          {...anchorDrawerToContainerProps}
          sx={{ zIndex: 1301 }}
        >
          {showDrawer && (
            <EntityJourney
              entityName={metadata.entityName}
              entityId={metadata.entityId}
              maptualListId={maptualListId}
              projectId={projectId}
              entityUData={entityUData}
            />
          )}
        </Drawer>
      </HorizontalContainer>
    </TitleBar>
  );
}
