import { Box } from '@mui/system';
import { useState } from 'react';
import {
  ContributionBreakdownResponse,
  EntityType,
} from '../../../clients/maptualAPI';
import { PowerscoreBar } from './powerscoreBar';
import { ContributionBreakdown } from './ContributionBreakdown';
import { CONTRIBUTION_TYPE } from './constants';

export const EntityPowerscoreExplainability = ({
  powerscore,
  contributionBreakdown,
  entityType,
}: {
  powerscore: number;
  contributionBreakdown: ContributionBreakdownResponse;
  entityType: EntityType;
}) => {
  const [expanded, setExpanded] = useState<CONTRIBUTION_TYPE | false>(false);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <PowerscoreBar
        currentValue={contributionBreakdown.currentValue.value ?? 0}
        potentialValue={contributionBreakdown.potential.value ?? 0}
        powerscore={powerscore}
        expanded={expanded}
      />
      <ContributionBreakdown
        contributionData={contributionBreakdown}
        expanded={expanded}
        setExpanded={setExpanded}
        entityType={entityType}
      />
    </Box>
  );
};
