import { baseConfigUrlOpenApi, configProxyApiInstanceOpenApi } from './config';
import {
  CustomerMarketsApi,
  CustomerProductLinesApi,
} from '../clients/configAPI';
import { apiInstanceCreator } from './utils';

const createApiInstance = apiInstanceCreator(
  baseConfigUrlOpenApi,
  configProxyApiInstanceOpenApi
);

export const marketsApi = createApiInstance(CustomerMarketsApi);
export const productLinesApi = createApiInstance(CustomerProductLinesApi);
