export const formatMarketUpdateRequest = (existingMarket, fieldsToUpdate) => ({
  ...existingMarket,
  ...fieldsToUpdate,
});

const extractDataGridIds = (basketInformation) =>
  basketInformation.map((basket) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...basketWithoutDataGridIndex } = basket;
    return basketWithoutDataGridIndex;
  });

export function formatObjectiveTypeUpdateRequest(
  existingObjectiveType,
  basketInformationUpdate,
  nonBasketInformationFieldsToUpdate
) {
  const updateObjectiveType = {
    ...existingObjectiveType,
    ...nonBasketInformationFieldsToUpdate,
  };
  updateObjectiveType.params = {
    ...existingObjectiveType.params,
    products: extractDataGridIds(basketInformationUpdate),
  };
  return updateObjectiveType;
}

export function formatObjectiveTypeAddRequest(
  basketInformation,
  nonBasketInformationFieldsToAdd
) {
  const addObjectiveType = {
    ...nonBasketInformationFieldsToAdd,
    params: {},
  };
  addObjectiveType.params = {
    products: extractDataGridIds(basketInformation),
  };
  return addObjectiveType;
}

const parseDateToUnix = (dateString) => {
  const dateObject = new Date(dateString);
  return dateObject.getTime() / 1000;
};

export function formatSegmentUpdateRequest(existingSegment, fieldsToUpdate) {
  const result = {
    ...removeEmptyValues(existingSegment),
    segmentCreatedDate: parseDateToUnix(existingSegment.segmentCreatedDate),
    segmentLastUpdated: Math.floor(new Date().getTime() / 1000),
    ...fieldsToUpdate,
  };
  if (!fieldsToUpdate.maptualList) {
    delete result.maptualList;
  }
  return result;
}

function removeEmptyValues(obj) {
  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([_, value]) => value !== null)
  );
}
