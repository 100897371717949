import { styled } from '@mui/material';
import { CADENCE } from '@odaia/domain/src';
import {  useCallPlanContext } from './CallPlanContext';

const TableWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
}));

const stickyColumns = [
  {
    id: 'rank',
    label: 'Rank',
    message: 'HCPs are ranked by importance to call.',
    visible: true,
    width: 40,
  },
  {
    id: 'name',
    label: 'Name',
    visible: true,
    width: 150,
  },
];

const nonStickyColumns = [
  {
    id: 'address',
    subLabel: 'Address',
    visible: true,
    width: 175,
  },
  {
    id: 'productScore',
    subLabel: 'Curated Product',
    message: 'The product we recommend focusing on with the HCP.',
    visible: true,
    width: 150,
  },
];

const activityColumns = [
  {
    id: 'curatedHcpsCalled',
    subLabel: '% Called When Curated',
    visible: true,
    width: 140,
  },
  {
    id: 'totalCalls',
    subLabel: 'Total Calls',
    visible: true,
    width: 70,
  },
  {
    id: 'segments',
    subLabel: 'Segments',
    visible: true,
    width: 150,
  },
  {
    id: 'segmentsCustomer',
    subLabel: 'Customer Segments',
    visible: true,
    width: 150,
  },
];

export type TableComponentProps = {
  regionId: string;
  hcpView: boolean;
  curatedType: string[];
  pageSize: number;
  showPowerscore: boolean;
  productLineId: string;
  projectId: string;
  objectiveId: string;
  cadence: string;
  timestamp: string;
  activitySubtitle: string;
  activityCadence: string;
  projectTargetProducts: unknown[];
  stickyColumns: unknown[];
  nonStickyColumns: unknown[];
  metricColumns: unknown[];
  activityColumns: unknown[];
  showProduct: boolean;
  fullHeight: boolean;
  onRowClick: (entityId: string) => void;
  RowContentComponent:  (props: RowComponentProps) => JSX.Element
}

export type RowComponentProps = { 
  objectiveId: string;
  cadence: string;
  row: Record<string, unknown>;
  rank: number;
  onRowClick: (entityId: string) => void;
  isLoading: boolean;
 }

type CallPlanTableProviderProps = {
  onRowClick: (entityId: string) => void;
  RowComponent: (props: RowComponentProps) => JSX.Element;
  TableComponent: (props: TableComponentProps) => JSX.Element;
}

export const CallPlanTableProvider = ({ onRowClick, RowComponent, TableComponent }:CallPlanTableProviderProps) => {
  const {
    latestCurationTimestamp,
    isTimestampLoading,
    isProductsLoading,
    projectTargetProducts,
    productLineId,
    projectId,
    regionId,
  } = useCallPlanContext()

  if (isTimestampLoading || isProductsLoading) {
    return null;
  }

  return (
    <TableWrapper>
      <TableComponent
        regionId={regionId}
        objectiveId=""
        timestamp={latestCurationTimestamp}
        curatedType={['curated']}
        cadence={CADENCE.WEEK}
        hcpView
        pageSize={10}
        RowContentComponent={RowComponent}
        stickyColumns={stickyColumns}
        nonStickyColumns={nonStickyColumns}
        metricColumns={[]}
        activityColumns={activityColumns}
        activitySubtitle="Quarter to Date"
        activityCadence={CADENCE.DAY}
        showPowerscore
        productLineId={productLineId}
        projectTargetProducts={projectTargetProducts}
        projectId={projectId}
        showProduct
        fullHeight
        onRowClick={onRowClick}
      />
    </TableWrapper>
  );
};
