import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { MarketPerformanceHeader } from './MarketPerformanceHeader';
import { useMarketPerformanceMetadataContext } from './data/useMarketPerformanceMetadataContext';
import { useMarketPerformanceChartContext } from './data/useMarketPerformanceChartContext';
import { MarketPerformanceMetadataContext } from './data/marketPerformanceMetadataContext';
import { MarketPerformanceBarChart } from './MarketPerformanceBarChart';
import { MarketPerformanceLineChart } from './MarketPerformanceLineChart';
import { METRIC_DATA_TYPE } from '../constants';
import { MarketPerformanceChartContext } from './data/marketPerformanceChartContext';
import { PredictionDisclaimer } from './MarketPerformancePredictionDisclaimer';
import { EntityType } from '../../../../clients/maptualAPI';

const Wrapper = styled(Box)(({ theme: { themeColors, spacing } }) => ({
  maxHeight: 676,
  borderRadius: 10,
  backgroundColor: themeColors.cardBackgroundColor,
  border: `1px solid ${themeColors.contentCardBorderColor}`,
  marginBottom: spacing(2),
  padding: spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  h4: {
    color: themeColors.tableHeaderContentColor,
  },
}));

export const MarketPerformanceCard = ({
  projectId,
  regionId,
  objectiveId,
  entityId,
  entityType = EntityType.Hcp,
}: {
  projectId: string;
  regionId: string;
  objectiveId: string;
  entityId: string;
  entityType?: EntityType;
}) => {
  const marketPerformanceMetadataContext = useMarketPerformanceMetadataContext(
    projectId,
    regionId,
    objectiveId,
    entityId,
    entityType
  );

  const marketPerformanceChartContext = useMarketPerformanceChartContext(
    marketPerformanceMetadataContext
  );

  return (
    <MarketPerformanceMetadataContext.Provider
      value={marketPerformanceMetadataContext}
    >
      <Wrapper data-testid="market-performance-card">
        <Typography variant="h4" align="left">
          Market Performance
        </Typography>

        <MarketPerformanceHeader />
        <MarketPerformanceChartContext.Provider
          value={marketPerformanceChartContext}
        >
          {marketPerformanceMetadataContext?.selectedCardTab ===
            METRIC_DATA_TYPE.VOLUME && <MarketPerformanceLineChart />}

          {marketPerformanceMetadataContext?.selectedCardTab ===
            METRIC_DATA_TYPE.SHARE && <MarketPerformanceBarChart />}
          <PredictionDisclaimer />
        </MarketPerformanceChartContext.Provider>
      </Wrapper>
    </MarketPerformanceMetadataContext.Provider>
  );
};
