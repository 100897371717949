import { QueryClient } from 'react-query';
import { useEffect } from 'react';
import { EntityType } from '../../../../clients/maptualAPI';
import { fieldApi } from '../../../../request/maptualApiClient';

export const useEagerLoadEntityPowerscoreContribution = async ({
  entityId,
  objectiveIds,
  projectId,
  regionId,
  entityType,
}: {
  entityId: string;
  objectiveIds: string[];
  projectId: string;
  regionId: string;
  entityType: EntityType;
}) => {
  useEffect(() => {
    if (
      !objectiveIds?.length ||
      !entityId ||
      !projectId ||
      !regionId ||
      !entityType
    ) {
      return;
    }

    const queryClient = new QueryClient();

    objectiveIds.forEach(async (objectiveId) =>
      queryClient.prefetchQuery({
        queryKey: [
          'entity-powerscore-contribution',
          entityId,
          entityType,
          projectId,
          regionId,
          objectiveId,
        ],
        queryFn: async () => {
          const response = await fieldApi.getContributionBreakdown({
            entityId,
            entityType,
            projectId,
            regionId,
            objectiveId,
          });
          return response.data;
        },
        staleTime: 300000,
        refetchOnMount: false,
      })
    );
  }, []);
};
