import {
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { METRIC_UNIT } from '../../constants';
import { Cadence, MetricData } from '../../types';
import { useOpportunityMetadata } from './useOpportunityMetadata';
import { AppContext } from '../../../../../containers/application/appContext';
import { EntityType } from '../../../../../clients/maptualAPI';

export interface OpportunityMetadataContextType {
  productLineId: string;
  regionId: string;
  projectId: string;
  objectiveId: string;
  entityId: string;
  entityType: EntityType;
  metadata: MetricData;
  isMetadataLoading: boolean;
  isMetadataError: boolean;
  refetchMetadata: () => void;
  selectedMetric: string;
  setSelectedMetric: Dispatch<SetStateAction<string>>;
  selectedCadence: Cadence;
  setSelectedCadence: Dispatch<SetStateAction<Cadence>>;
  selectedUnit: METRIC_UNIT;
  setSelectedUnit: Dispatch<SetStateAction<METRIC_UNIT>>;
  isMetadataIdle: boolean;
}

export const useOpportunityMetadataContext = (
  projectId: string,
  regionId: string,
  objectiveId: string,
  entityType: EntityType,
  entityId: string
): OpportunityMetadataContextType => {
  const { projectList } = useContext(AppContext);
  const { productLineId } =
    projectList?.find((proj) => proj.projectId === projectId) || {};

  const [selectedMetric, setSelectedMetric] = useState<string>();
  const [selectedCadence, setSelectedCadence] = useState<Cadence>();
  const [selectedUnit, setSelectedUnit] = useState<METRIC_UNIT>();

  const {
    isLoading: isMetadataLoading,
    data: metadata,
    refetch: refetchMetadata,
    isError: isMetadataError,
    isIdle: isMetadataIdle,
  } = useOpportunityMetadata(objectiveId, productLineId, entityId, entityType);

  useEffect(() => {
    if (metadata?.metrics?.length) {
      setSelectedMetric(metadata.metrics[0].rxType);
      setSelectedCadence(metadata.metrics[0].cadences[0]);
      setSelectedUnit(metadata.metrics[0].unit);
    }
  }, [metadata]);

  useEffect(() => {
    if (selectedMetric) {
      const isCadenceAvailable = metadata?.metrics?.find(
        (metric) =>
          metric.rxType === selectedMetric &&
          metric.cadences.some((c) => c.id === selectedCadence?.id)
      );

      if (!isCadenceAvailable) {
        const fallbackCadence = metadata?.metrics?.find(
          (metric) => metric.rxType === selectedMetric
        )?.cadences[0];

        setSelectedCadence(fallbackCadence);
      }
    }
  }, [selectedMetric]);

  return {
    productLineId,
    regionId,
    projectId,
    objectiveId,
    entityId,
    entityType,
    metadata,
    isMetadataLoading,
    isMetadataError,
    refetchMetadata,
    selectedMetric,
    setSelectedMetric,
    selectedCadence,
    setSelectedCadence,
    selectedUnit,
    setSelectedUnit,
    isMetadataIdle,
  };
};
