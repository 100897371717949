import { useQuery } from 'react-query';
import { fieldApi } from '../../../../../request/maptualApiClient';
import {
  ChartMetadataResponse,
  EntityType,
} from '../../../../../clients/maptualAPI';

export const useProductPerformanceMetadata = (
  objectiveId: string,
  entityType: EntityType
): {
  data?: ChartMetadataResponse;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { data, isLoading, refetch, isError, isIdle } = useQuery({
    queryKey: ['product-performance-metadata', objectiveId, entityType],
    queryFn: async () => {
      const response = await fieldApi.getProductPerformanceMetadata({
        objectiveId,
        entityType,
      });

      return response.data;
    },
    enabled: !!objectiveId,
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
