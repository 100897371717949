import { SHORT_FORM_CADENCE } from '../../constants';
import { ChartDataset } from '../../types';
import { useOpportunityData } from './useOpportunityData';
import { EntityType } from '../../../../../clients/maptualAPI';

interface OpportunityChartParams {
  entityId: string;
  projectId: string;
  regionId: string;
  objectiveId: string;
  entityType: EntityType;
  cadence: SHORT_FORM_CADENCE;
  metric: string;
}

export interface ChartData {
  data: ChartDataset[];
  title: string;
}

export const useOpportunityChart = ({
  projectId,
  regionId,
  objectiveId,
  entityId,
  entityType,
  metric,
  cadence,
}: OpportunityChartParams): {
  data: ChartData | undefined;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { isLoading, data, isError, isIdle, refetch } = useOpportunityData({
    projectId,
    regionId,
    objectiveId,
    entityId,
    entityType,
    metric,
    cadence,
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
