import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getTerritoryOverviewData } from '../../request/territoryOverviewRequests';
import { disclaimerOptions } from './constants';
import { fieldApi } from '../../request/maptualApiClient';

export const useTerritoryOverviewData = ({
  distributionType,
  setDistributionType,
  projectId,
  maptualListId,
  sfMaptualListId,
  selectedMetric,
  selectedCategory,
}) => {
  const {
    isFetching: isMetadataLoading,
    data: metadataResponse,
    refetch: refetchMetadata,
  } = useQuery({
    queryKey: ['territory-overview-metadata', projectId, sfMaptualListId],
    queryFn: async () => {
      try {
        const response = await fieldApi.getTerritoryOverviewMetadata({
          projectId,
          regionId: sfMaptualListId,
        });

        if (response?.status === 204) {
          return {
            data: disclaimerOptions.INVALID_REGION,
            status: response.status,
          };
        }

        return response;
      } catch (err) {
        return { status: 500, ...err.response };
      }
    },
    cacheTime: 0,
    enabled: !!projectId && !!sfMaptualListId,
  });

  const {
    isFetching: isDataLoading,
    isError: isDataError,
    isIdle: isDataRequestIdle,
    data: dataResponse,
    status: dataStatus,
  } = useQuery({
    queryKey: [
      'territory-overview',
      projectId,
      sfMaptualListId,
      distributionType,
      selectedMetric,
      selectedCategory,
    ],
    queryFn: async () => {
      const params = {
        projectId,
        regionId: sfMaptualListId,
        metric: selectedMetric,
        distribution: distributionType,
        categoryId: selectedCategory,
      };

      const response = await getTerritoryOverviewData(params);

      if (response?.status === 204) {
        return {
          data: disclaimerOptions.INVALID_REGION,
          status: 204,
        };
      }
      return response.data;
    },
    enabled:
      !!projectId &&
      !!sfMaptualListId &&
      !!selectedMetric &&
      !!selectedCategory &&
      !!distributionType,
  });

  useEffect(() => {
    if (
      metadataResponse?.status === 200 &&
      metadataResponse?.data &&
      metadataResponse.data !== disclaimerOptions.INVALID_REGION
    ) {
      const metadata = metadataResponse.data;
      const defaultDistribution = metadata.availableDistributions[0];

      setDistributionType(defaultDistribution);
    }
  }, [metadataResponse?.data, metadataResponse?.status]);

  const [previousMaptualListId, setPreviousMaptualListId] =
    useState(maptualListId);

  useEffect(() => {
    if (!previousMaptualListId) {
      setPreviousMaptualListId(maptualListId);
    } else if (maptualListId !== previousMaptualListId) {
      refetchMetadata();
      setPreviousMaptualListId(maptualListId);
    }
  }, [maptualListId, previousMaptualListId]);

  return {
    dataResponse,
    status: dataStatus,
    metadataResponse,
    metadataStatus: metadataResponse?.status,
    isDataLoading,
    isDataError,
    isDataRequestIdle,
    isMetadataLoading,
  };
};
