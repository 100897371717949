import { Box, styled } from '@mui/system';
import { Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { CONTRIBUTION_TYPE } from './constants';

export const PowerscoreBar = ({
  potentialValue,
  currentValue,
  powerscore,
  expanded,
}: {
  potentialValue: number;
  currentValue: number;
  powerscore: number;
  expanded: CONTRIBUTION_TYPE | false;
}) => {
  const powerscoreDifference = 10 - powerscore;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <PowerscoreText variant="title3">
        PowerScore: {powerscore} of 10
      </PowerscoreText>
      <PowerscoreWrapper
        gridTemplateColumns={`${powerscore * 10}% ${
          powerscoreDifference * 10
        }%`}
      >
        <PowerscoreComponent>
          <CurrentValueBar width={`${currentValue}%`}>
            {expanded === CONTRIBUTION_TYPE.CURRENT && powerscore > 0 && (
              <ArrowIcon />
            )}
          </CurrentValueBar>
          <PotentialBar width={`${potentialValue}%`}>
            {expanded === CONTRIBUTION_TYPE.POTENTIAL && powerscore > 0 && (
              <ArrowIcon />
            )}
          </PotentialBar>
        </PowerscoreComponent>
        <PowerscoreComponent
          display="grid"
          gridTemplateColumns={`repeat(${powerscoreDifference}, 1fr)`}
        >
          {[...Array(powerscoreDifference)].map((_, i) => (
            <PowerscoreSpacer key={`powerscore-gap-${i}`} />
          ))}
        </PowerscoreComponent>
      </PowerscoreWrapper>
    </Box>
  );
};

const Bar = styled(Box)(() => ({
  height: 11,
  position: 'relative',
}));

const PowerscoreWrapper = styled(Box)({
  display: 'grid',
  paddingBottom: 6,
});

const PowerscoreComponent = styled(Box)({
  display: 'flex',
  gap: 1,
});

const CurrentValueBar = styled(Bar)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.dataGeneralColor3,
}));

const PotentialBar = styled(Bar)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.dataGeneralColor,
}));

const PowerscoreSpacer = styled(Bar)(({ theme: { themeColors } }) => ({
  width: '100%',
  backgroundColor: themeColors.progressBarBackground,
}));

const PowerscoreText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
}));

const ArrowIcon = styled(ArrowDropUpIcon)(({ theme: { themeColors } }) => ({
  width: 20,
  height: 20,
  position: 'absolute',
  left: 'calc(50% - 10px)',
  top: 5,
  color: themeColors.rankedCategoricalScaleActiveChevron,
}));
