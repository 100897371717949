import { useContext, useMemo, useState } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { SentryRoute } from '../../../../sentryRoute';
import { ROUTES } from '../../../../constants';
import LoginHandler from '../../loginHandler';
import { ContentWrapper } from '../../contentWrapper';
import { FieldContentWrapper } from './FieldContentWrapper';
import FieldViewer from '../../../fieldview/fieldViewer';
import { Wrapper } from '../../../territoryOverview/Wrapper';
import useFeatureToggles from '../../../../hooks/useFeatureToggles';
import { AppContext } from '../../appContext';
import EmptyView from '../../../../components/emptyview/emptyView';
import { Navigation } from './navigation/Navigation';
import { FieldWrapper } from './FieldWrapper';
import { EntityOverview } from '../../../entityOverview';
import {
  getDemoAccountStatus,
  userHasCallPlanAccess,
} from '../../../../request/config';
import { CallPlan } from './callPlan/CallPlan';

export const Field = ({ profile }) => {
  const location = new URLSearchParams(window.location.search);
  const [redirectUrl] = useState(location.get('redirectUrl'));
  const featureToggles = useFeatureToggles();
  const { isProjectListLoading } = useContext(AppContext);
  const isDemoAccount = getDemoAccountStatus();

  const redirectPath = useMemo(
    () =>
      redirectUrl ? `${redirectUrl}` : `${ROUTES.FIELD}${ROUTES.PROJECTS}`,
    [redirectUrl]
  );

  return (
    <Router>
      <SentryRoute
        path={[
          `${ROUTES.FIELD}/(projects)?/:projectId?/(maptualListId)?/:maptualListId?/(custom)/:customListType`,
          `${ROUTES.FIELD}/(projects)?/:projectId?/(maptualListId)?/:maptualListId?`,
          `${ROUTES.FIELD}/(projects)?`,
        ]}
      >
        <FieldWrapper>
          <Navigation />
          <ContentWrapper>
            <FieldContentWrapper>
              {isProjectListLoading ? (
                <EmptyView isLoading loadingTitle="Loading Content ..." />
              ) : (
                <Switch>
                  <SentryRoute
                    exact
                    path={ROUTES.LOGIN}
                    render={() => <LoginHandler redirectTo={redirectPath} />}
                  />
                  {(isDemoAccount ||
                    (userHasCallPlanAccess(profile, true) &&
                      featureToggles(profile.userGroup, 'curatedList'))) && (
                    <SentryRoute
                      path={[
                        `${ROUTES.FIELD}${ROUTES.PROJECTS}/:projectId?/(maptualListId)?/:maptualListId?${ROUTES.CALL_PLAN}`,
                      ]}
                      render={() => <CallPlan />}
                    />
                  )}
                  {featureToggles(
                    profile.userGroup,
                    'showTerritoryOverview'
                  ) && (
                    <SentryRoute
                      path={[
                        `${ROUTES.FIELD}${ROUTES.PROJECTS}/:projectId?/(maptualListId)?/:maptualListId?${ROUTES.TERRITORY_OVERVIEW}`,
                        `${ROUTES.FIELD}${ROUTES.TERRITORY_OVERVIEW}`,
                      ]}
                      render={(innerProps) => <Wrapper {...innerProps} />}
                    />
                  )}

                  {featureToggles(profile.userGroup, 'showAccountView') && (
                    <SentryRoute
                      path={[
                        `${ROUTES.FIELD}${ROUTES.PROJECTS}/:projectId?/(maptualListId)?/:maptualListId?${ROUTES.ENTITY_OVERVIEW}/:entityType?`,
                      ]}
                      render={(innerProps) => {
                        const entityTypeFromRoute =
                          innerProps?.location?.pathname?.split('/').pop();

                        const titleMap = {
                          outlet: 'Retail Outlets',
                          'direct-account': 'Direct Accounts',
                        };

                        const props = {
                          ...innerProps,
                          entityType: entityTypeFromRoute.replace(/-/, '_'),
                          title:
                            titleMap[entityTypeFromRoute] ?? 'Retail Outlets',
                        };

                        return <EntityOverview {...props} />;
                      }}
                    />
                  )}
                  <SentryRoute
                    path={[
                      `${ROUTES.FIELD}${ROUTES.PROJECTS}/:projectId?/(maptualListId)?/:maptualListId?${ROUTES.HCPS}${ROUTES.MAP}`,
                      `${ROUTES.FIELD}${ROUTES.PROJECTS}/:projectId?/(maptualListId)?/:maptualListId?${ROUTES.HCPS}`,
                      `${ROUTES.FIELD}${ROUTES.PROJECTS}/:projectId?/(maptualListId)?/:maptualListId?/(custom)?/:customListType?`,
                      `${ROUTES.FIELD}${ROUTES.PROJECTS}/:projectId?/(maptualListId)?/:maptualListId?/(entityId)?/:entityId?`,
                    ]}
                    render={(innerProps) => (
                      <FieldViewer
                        {...innerProps}
                        profile={profile}
                        redirectUrl={redirectUrl}
                      />
                    )}
                  />
                </Switch>
              )}
            </FieldContentWrapper>
          </ContentWrapper>
        </FieldWrapper>
      </SentryRoute>
      <SentryRoute exact path={ROUTES.ROOT}>
        <Redirect to={redirectPath} />
      </SentryRoute>
      <SentryRoute
        exact
        path={ROUTES.LOGIN}
        render={() => <LoginHandler redirectTo={redirectPath} />}
      />
      <SentryRoute exact path={ROUTES.INDEX_HTML}>
        <Redirect to={redirectPath} />
      </SentryRoute>
      <SentryRoute exact path={ROUTES.FIELD}>
        <Redirect to={redirectPath} />
      </SentryRoute>
    </Router>
  );
};
