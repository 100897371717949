/* eslint-disable react/no-unused-prop-types */
import React, { useContext, useState } from 'react';
import { Box, Button, Popover, styled, Typography } from '@mui/material';
import { MoreHorizOutlined, Apartment, WorkOutline } from '@mui/icons-material';
import { StethoscopeIcon } from '../../application/appViews/field/navigation/viewsList/StethoscopeIcon';
import { FieldContext } from '../../application/appViews/field/fieldContext';
import { EntityType } from '../../../clients/maptualAPI';

const EllipsisIcon = styled(MoreHorizOutlined)(
  ({ theme: { themeColors } }) => ({
    color: themeColors.neutral70,
    width: 16,
    height: 16,
    transformOrigin: 'center',
  })
);

const StyledButton = styled(Button)(({ theme: { themeColors }, isActive }) => ({
  padding: 0,
  minWidth: 40,
  minHeight: 40,

  '& .MuiSvgIcon-root': {
    color: themeColors.utilityActiveContentColor,
  },

  backgroundColor: isActive
    ? themeColors.utilityActiveSurfaceColor
    : 'transparent',
}));

const StyledPopover = styled(Popover)(({ theme: { themeColors } }) => ({
  marginTop: 3,
  '& .MuiPopover-paper': {
    width: 320,
    backgroundColor: themeColors.popoverBackground,
    borderColor: themeColors.borderPrimaryColor,
  },
}));

const FlexBox = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: spacing(2, 0),
}));

const StyledRouteRow = styled(Box)(({ theme: { spacing, themeColors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: spacing(1.5, 3.5),

  '> :first-of-type': {
    marginRight: spacing(1.5),
  },

  '&:hover': {
    backgroundColor: themeColors.tablePopoverHoverSurface,
    cursor: 'pointer',
  },
}));

const StyledApartmentIcon = styled(Apartment)(({ theme: { themeColors } }) => ({
  flexShrink: 0,
  height: 20,
  width: 20,

  '& path': {
    fill: themeColors.listIconColor,
  },
}));

const StyledWorkOutlineIcon = styled(WorkOutline)(
  ({ theme: { themeColors } }) => ({
    flexShrink: 0,
    height: 20,
    width: 20,

    '& path': {
      fill: themeColors.listIconColor,
    },
  })
);

const Text = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
  wordWrap: 'break-word',
}));

interface EntityView {
  displayName: string;
  entityType:
    | typeof EntityType.Hcp
    | typeof EntityType.Outlet
    | typeof EntityType.DirectAccount;
}

const ENTITY_TYPE_ICON = {
  [EntityType.Hcp]: <StethoscopeIcon />,
  [EntityType.Outlet]: <StyledApartmentIcon />,
  [EntityType.DirectAccount]: <StyledWorkOutlineIcon />,
};

export const ExternalLinkPopover = ({
  row,
  regionName,
  toHCPClick,
  toAccountViewClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  if (!row) {
    return null;
  }

  const handleExternalLinkClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { entityViews } = useContext(FieldContext);
  const entitiesAvailable = entityViews?.map((view) => ({
    displayName: view.displayName,
    entityType: view.entityType,
  }));

  return (
    <>
      <StyledButton
        onClick={handleExternalLinkClick}
        isActive={isPopoverOpen}
        data-testid={`${regionName}-open-external-link-popover-btn`}
      >
        <EllipsisIcon data-testid="ellipsis-icon" />
      </StyledButton>
      <StyledPopover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FlexBox>
          <StyledRouteRow
            onClick={toHCPClick}
            data-testid={`${regionName}-hcp-external-link-popover-btn`}
          >
            {ENTITY_TYPE_ICON[EntityType.Hcp]}
            <Text variant="body1">{`View HCPs in ${regionName}`}</Text>
          </StyledRouteRow>

          {entitiesAvailable &&
            entitiesAvailable.map(({ displayName, entityType }: EntityView) => (
              <StyledRouteRow
                onClick={() => toAccountViewClick(displayName)}
                key={displayName}
                data-testid={`${regionName}-${displayName
                  .replace(/\s/g, '-')
                  .toLowerCase()}-external-link-popover-btn`}
              >
                {ENTITY_TYPE_ICON[entityType]}
                <Text variant="body1">{`View ${displayName} in ${regionName}`}</Text>
              </StyledRouteRow>
            ))}
        </FlexBox>
      </StyledPopover>
    </>
  );
};
