import { useQuery } from 'react-query';
import {
  CADENCE_LABEL,
  METRIC_DATA_TYPE,
  METRIC_UNIT,
  SHORT_FORM_CADENCE,
} from '../../constants';
import { fieldApi } from '../../../../../request/maptualApiClient';
import {
  ChartMetadataResponse,
  EntityType,
} from '../../../../../clients/maptualAPI';

export interface Cadence {
  id: SHORT_FORM_CADENCE;
  label: CADENCE_LABEL;
}

export interface Metric {
  cadences: Cadence[];
  rxType: string;
  unit: METRIC_UNIT;
  displayName: string;
}

export interface MetricData {
  metrics: Metric[];
  chartType: string;
  metricDataType: METRIC_DATA_TYPE;
}

export const useMarketPerformanceMetadata = (
  objectiveId: string,
  entityType: EntityType
): {
  data?: ChartMetadataResponse;
  isLoading: boolean;
  refetch: () => {};
  isError: boolean;
  isIdle: boolean;
} => {
  const { data, isLoading, refetch, isError, isIdle } = useQuery({
    queryKey: ['market-performance-metadata', objectiveId, entityType],
    queryFn: async () => {
      const response = await fieldApi.getMarketPerformanceMetadata({
        entityType,
        objectiveId,
      });

      return response.data;
    },
    enabled: !!objectiveId,
  });

  return {
    data,
    isLoading,
    refetch,
    isError,
    isIdle,
  };
};
