import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { OpportunityHeader } from './OpportunityHeader';
import { useOpportunityMetadataContext } from './data/useOpportunityMetadataContext';
import { useOpportunityChartContext } from './data/useOpportunityChartContext';
import { OpportunityMetadataContext } from './data/opportunityMetadataContext';
import { OpportunityChartContext } from './data/opportunityChartContext';
import { OpportunityLineChart } from './OpportunityLineChart';
import { PredictionDisclaimer } from './OpportunityPredictionDisclaimer';
import { EntityType } from '../../../../clients/maptualAPI';

const Wrapper = styled(Box)(({ theme: { themeColors, spacing } }) => ({
  maxHeight: 676,
  borderRadius: 10,
  backgroundColor: themeColors.cardBackgroundColor,
  border: `1px solid ${themeColors.contentCardBorderColor}`,
  marginBottom: spacing(2),
  padding: spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  h4: {
    color: themeColors.tableHeaderContentColor,
  },
}));

export const OpportunityCard = ({
  projectId,
  regionId,
  objectiveId,
  entityId,
  entityType = EntityType.Hcp,
}: {
  projectId: string;
  regionId: string;
  objectiveId: string;
  entityId: string;
  entityType?: EntityType;
}) => {
  const opportunityMetadataContext = useOpportunityMetadataContext(
    projectId,
    regionId,
    objectiveId,
    entityType,
    entityId
  );

  const opportunityChartContext = useOpportunityChartContext(
    opportunityMetadataContext
  );

  return (
    <OpportunityMetadataContext.Provider value={opportunityMetadataContext}>
      <Wrapper data-testid="opportunity-card">
        <Typography variant="h4" align="left">
          Opportunity
        </Typography>

        <OpportunityHeader />
        <OpportunityChartContext.Provider value={opportunityChartContext}>
          <OpportunityLineChart />
          <PredictionDisclaimer />
        </OpportunityChartContext.Provider>
      </Wrapper>
    </OpportunityMetadataContext.Provider>
  );
};
