/* eslint-disable no-restricted-syntax */
import { useState, useEffect } from "react";
import { styled, Typography } from "@mui/material";

import { formatLastContactedDate } from "@odaia/domain/src";

import ProgressBar from "@odaia/ui/src/ProgressBar";

import { BadgeContainer } from "../../BadgeContainer";
import { GenericTooltip, TooltipContent } from "@odaia/ui/src/tooltip";
import { Row } from "../../entitiesTable/Row";
import { NumberContainer } from "../../numberContainer";
import { PowerScore } from "../../powerScore";
import { useCallPlanContext } from "../CallPlanContext";

const StyledCardColumnSection = styled("div")(({ theme: { themeColors } }) => ({
  display: "flex",
  gap: 32,
  alignItems: "center",
  padding: 16,
  borderLeft: `1px solid ${themeColors.buttonBorderColor}`,
  borderRight: `1px solid ${themeColors.buttonBorderColor}`,
  minWidth: 175,
}));

const ScoreContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const StyledColumn = styled("div")(({ width }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width,
  minWidth: 150,
}));

const StyledTitle = styled(Typography)(
  ({ theme: { themeColors }, marginLeft }) => ({
    color: themeColors.secondaryTextColor,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "120%",
    textAlign: "left",
    textTransform: "capitalize",
    marginLeft,
  })
);

const StyledSubtitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.tertiaryColor,
  textTransform: "capitalize",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16px",
  textAlign: "left",
}));

type CallPlanRowProps = {
  objectiveId: string;
  cadence: string;
  row: unknown;
  isLoading: boolean;
  rank: unknown;
  onRowClick: (entityId: string) => void;
  crmData: unknown;
  isCrmDataLoading: boolean;
  entitySegments: unknown;
  isSegmentsLoading: boolean;
};

export const CallPlanRow = ({
  objectiveId,
  cadence,
  row,
  isLoading,
  rank,
  onRowClick,
  crmData,
  isCrmDataLoading,
  entitySegments,
  isSegmentsLoading,
}: CallPlanRowProps) => {
  const [nonCuratedCalls, setNonCuratedCalls] = useState();
  const [curatedCalls, setCuratedCalls] = useState();
  const [curatedCount, setCuratedCount] = useState();
  const [segments, setSegments] = useState([]);
  const [cityTooltipContent, setCityTooltipContent] = useState(null);

  const { productLineId, projectTargetProducts, isProductsLoading, profile } =
    useCallPlanContext();

  const isSingleProduct = projectTargetProducts?.length === 1;

  useEffect(() => {
    if (entitySegments) {
      setSegments(entitySegments);
    }
  }, [entitySegments]);

  useEffect(() => {
    if (
      crmData &&
      crmData?.nonCuratedCalls !== undefined &&
      crmData?.curatedCalls !== undefined &&
      crmData?.timesCurated !== undefined
    ) {
      setNonCuratedCalls(crmData.nonCuratedCalls);
      setCuratedCalls(crmData.curatedCalls);
      setCuratedCount(crmData.timesCurated);
    }
  }, [crmData]);

  useEffect(() => {
    if (row.entity) {
      const tooltipContent = TooltipContent([
        {
          id: 0,
          title: `${row.entity?.city}, ${row.entity?.province.toUpperCase()}`,
        },
        { id: 1, title: row.entity?.address || "No Address" },
        { id: 2, title: row.entity?.postalCode },
      ]);
      setCityTooltipContent(tooltipContent);
    }
  }, [
    row?.entity?.city,
    row?.entity?.province,
    row.entity?.address,
    row.entity?.postalCode,
  ]);

  return (
    <Row
      row={row}
      objectiveId={objectiveId}
      cadence={cadence}
      key={row.id}
      isShimmer={isLoading}
      onRowClick={() => onRowClick(row.entity.id)}
      showPowerscore={isSingleProduct}
      rowTitle={row.entity.name}
      rowSubtitle={`${row.entity?.specialty} - ${formatLastContactedDate(
        row.entity?.lastContactedDate
      )}`}
      showRank
      rank={rank}
      productLineId={productLineId}
      showCustomListIcon
      profile={profile}
    >
      <StyledColumn width="175px">
        <GenericTooltip title={cityTooltipContent}>
          <StyledTitle variant="body2" noWrap>
            {`${row.entity?.city}, ${row.entity?.province.toUpperCase()}`}
          </StyledTitle>
          <StyledSubtitle variant="body2" noWrap>
            {row.entity?.address || "No Address"}
          </StyledSubtitle>
          <StyledSubtitle variant="body2" noWrap>
            {row.entity?.postalCode}
          </StyledSubtitle>
        </GenericTooltip>
      </StyledColumn>
      <StyledColumn width="150px">
        <ScoreContainer>
          <PowerScore score={Math.round(row.score)} />
          <StyledTitle variant="body2" marginLeft="8px">
            {row.product?.toLowerCase()}
          </StyledTitle>
        </ScoreContainer>
      </StyledColumn>
      <StyledCardColumnSection>
        <ProgressBar
          isLoading={isCrmDataLoading || isProductsLoading}
          numerator={curatedCalls}
          denominator={curatedCount}
        />
        <NumberContainer
          isLoading={isCrmDataLoading || isProductsLoading}
          value={nonCuratedCalls + curatedCalls || 0}
        />
        <BadgeContainer
          content={segments.filter(
            (segment) => segment.type.toUpperCase() !== "CUSTOMER"
          )}
          isLoading={isSegmentsLoading}
        />
        <BadgeContainer
          content={segments.filter(
            (segment) => segment.type.toUpperCase() === "CUSTOMER"
          )}
          isLoading={isSegmentsLoading}
        />
      </StyledCardColumnSection>
    </Row>
  );
};
