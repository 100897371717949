import {
  AccordionDetails,
  Box,
  Popper,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { METRIC_DATA_FORMAT } from './constants';
import {
  Contribution,
  ContributionCategoricalMetric,
  ContributionCategory,
  ContributionContentType,
  ContributionMetric,
  ContributionMetrics,
  ContributionSegment,
  ContributionSegments,
  EntityType,
} from '../../../clients/maptualAPI';
import {
  commaSeparatedStringList,
  formatMetricValue,
  METRIC_UNIT,
} from './utils';

const SEGMENT_NAMES = {
  starters: 'Starter',
  rising_stars: 'Rising Star',
};

export const ContributionBreakdownContent = ({
  content,
  entityType,
}: {
  content: Contribution;
  entityType: EntityType;
}) => {
  const hasNoContributingData = content.data.every((obj) => {
    const dataKey = Object.keys(obj).find((key) => key !== 'type');
    return dataKey && Array.isArray(obj[dataKey]) && obj[dataKey].length === 0;
  });

  return (
    <StyledAccordionDetails data-testid="powerscore-contribution-breakdown-content">
      {hasNoContributingData && (
        <Typography variant="body2">
          This {entityType} has had no engagement with{' '}
          {commaSeparatedStringList(content.baskets)} products over the recent
          12 months.
        </Typography>
      )}

      {Array.isArray(content.data) &&
        content.data.map((data, index) => {
          if (
            data.type === ContributionContentType.Metrics &&
            (data as ContributionMetrics).metrics.length > 0
          ) {
            return (
              <MetricsListItem
                key={`list-${index}`}
                metrics={(data as ContributionMetrics).metrics}
                entityType={entityType}
              />
            );
          }

          if (
            data.type === ContributionContentType.Segments &&
            (data as ContributionSegments).segments.length > 0
          ) {
            return (
              <SegmentsListItem
                key={`segments-${index}`}
                segments={(data as ContributionSegments).segments}
                entityType={entityType}
              />
            );
          }

          return null;
        })}
    </StyledAccordionDetails>
  );
};

const MetricsListItem = ({
  metrics,
  entityType,
}: {
  metrics: (ContributionMetric | ContributionCategoricalMetric)[];
  entityType: EntityType;
}) => (
  <Box>
    <Typography variant="body2">
      Over the recent 12 months, this {entityType} has:
    </Typography>
    <StyledList>
      {metrics.map((metric, index) =>
        metric.type === METRIC_DATA_FORMAT.CATEGORICAL ? (
          <CategoricalMetricListItem
            key={index}
            categories={(metric as ContributionCategoricalMetric).categories}
          />
        ) : (
          <li key={index}>
            <Tooltip
              title={formatMetricTooltip(metric as ContributionMetric)}
              arrow
              placement="bottom"
              PopperComponent={StyledTooltipPopper}
            >
              <UnderlinedText textDecoration="underline">
                {`${formatMetricValue(
                  (metric as ContributionMetric).value,
                  (metric as ContributionMetric).unit
                )} ${(metric as ContributionMetric).basketName} ${
                  (metric as ContributionMetric).metric
                }`}
              </UnderlinedText>
            </Tooltip>
          </li>
        )
      )}
    </StyledList>
  </Box>
);

const CategoricalMetricListItem = ({
  categories,
}: {
  categories: ContributionCategory[];
}) => (
  <>
    {categories.map((category: ContributionCategory) => (
      <li key={`categorical-metric-${category.label}`}>
        <span>
          {category.label}: {category.value}
        </span>
      </li>
    ))}
  </>
);

const SegmentsListItem = ({
  segments,
  entityType,
}: {
  segments: ContributionSegment[];
  entityType: EntityType;
}) => (
  <Typography variant="body2">
    This {entityType} is a{' '}
    {segments.map((segment, index) => (
      <span key={segment.name}>
        {/* TODO: Use tooltip version once we have segment metric in the description */}
        {/* <Tooltip */}
        {/*  title={getSegmentDescription( */}
        {/*    segment.name, */}
        {/*    entityType, */}
        {/*    segment.details.metric, */}
        {/*    segment.details.targetBasket, */}
        {/*    segment.details.competitiveBasket */}
        {/*  )} */}
        {/*  arrow */}
        {/*  placement="bottom" */}
        {/*  PopperComponent={StyledTooltipPopper} */}
        {/* > */}
        {/*  <UnderlinedText>{SEGMENT_NAMES[segment.name]}</UnderlinedText> */}
        {/* </Tooltip> */}

        {SEGMENT_NAMES[segment.name]}

        {segments.length > 1 && index < segments.length - 1 && ' and a '}
      </span>
    ))}
    .
  </Typography>
);

// const getSegmentDescription = (
//   segmentName: string,
//   entityType: EntityType,
//   metric: string,
//   targetBasket: string,
//   competitiveBasket: string = ''
// ) => {
//   const descriptions = {
//     starters: `${entityType}s that have generated ${targetBasket} ${metric} for the first time within the last 3 months, or are predicted to in the near future based on similarity to other ${entityType}s.`,
//     rising_stars: `${entityType}s that are predicted to generate above-average ${metric} volume in the total market (${targetBasket} + ${competitiveBasket}) in the coming 18 to 24 months.`,
//   };
//
//   return descriptions[segmentName];
// };

const StyledAccordionDetails = styled(AccordionDetails)(
  ({ theme: { themeColors } }) => ({
    padding: '0 12px 16px 31px',
    color: themeColors.secondaryTextColor,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  })
);

const StyledList = styled('ul')({
  marginTop: 12,
  marginBottom: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  fontSize: 14,
});

const UnderlinedText = styled('span')({
  textDecoration: 'underline',
  cursor: 'pointer',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 1.2,
});

const StyledTooltipPopper = styled(Popper)(({ theme: { themeColors } }) => ({
  '.MuiTooltip-arrow::before': {
    backgroundColor: themeColors.tooltipSurfaceColor,
  },
  '.MuiTooltip-tooltip': {
    '&.MuiTooltip-tooltipPlacementBottom': {
      marginTop: 5,
    },
    backgroundColor: themeColors.tooltipSurfaceColor,
    color: themeColors.tooltipContentColor,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.2,
  },
}));

const formatMetricTooltip = (metric: ContributionMetric) => {
  const dateTooltip = `${metric.dateFrom} to ${metric.dateTo}`;

  if (metric.unit === METRIC_UNIT.RATE) {
    return `Average from ${dateTooltip}`;
  }

  return dateTooltip;
};
