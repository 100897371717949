import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Skeleton, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Scrollbar from 'react-scrollbars-custom';

import { AppContext } from '../../containers/application/appContext';
import { FieldContext } from '../../containers/application/appViews/field/fieldContext';
import { getProjectList } from '../../request/projectRequests';

import { useEntity } from '../../containers/fieldview/useEntity';

import { EntityTitleBarContainer } from './entityTitleBar';
import { MultiObjectivesRow } from '../../containers/shared/MultiObjectivesRow';
import { trackObjectiveSelected } from '../../trackers/mixpanel';

import { AmaOptOutSection } from './amaOptOutSections/AmaOptOutSection';
import { AmaOptInSection } from './amaOptOutSections/AmaOptInSection';

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    flexGrow: 2,
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 640,
  },
  content: {
    width: '100%',
    maxWidth: 810,
    minWidth: 560,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  briefContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  sectionText: {
    fontSize: 22,
    fontWeight: 800,
    color: themeColors.primaryTextColor,
    width: 'auto',
  },
}));

const SkeletonLoadingBar = styled(Skeleton)(
  ({ theme: { themeColors, spacing } }) => ({
    backgroundColor: themeColors.surfaceEmpty,
    transform: 'scale(1)',
    marginBottom: spacing(2),
    height: 80,
    width: '100%',
  })
);

export const EntityMaptualInsightsView = ({
  snowflakeEntityId,
  insights,
  displayUserCreatedListsButton,
  standaloneParams,
  projectId,
  isNewProjectId,
  setIsNewProjectId,
  currentObjectiveId,
  setCurrentObjectiveId,
}) => {
  const { profile } = useContext(AppContext);
  const fieldContext = useContext(FieldContext);

  const snowflakeMaptualListId =
    standaloneParams?.snowflakeMaptualListId ||
    fieldContext?.maptualListMetadata?.sfMaptualListId;

  const { entity, isEntityBusy: isEntityDetailsLoading } = useEntity({
    entityId: snowflakeEntityId,
  });

  const classes = useStyles();

  const [activeObjective, setActiveObjective] = useState({});
  const [objectivesRowActiveObjective, setObjectivesRowActiveObjective] =
    useState({});
  const [viewMultiObjectives, setViewMultiObjectives] = useState(false);
  const [marketId, setMarketId] = useState(
    fieldContext?.project?.metadata?.marketId
  );

  const { data: projectList, isLoading: isProjectListLoading } = useQuery(
    ['project-list'],
    getProjectList,
    { enabled: !!standaloneParams }
  );

  useEffect(() => {
    if (!isProjectListLoading && projectList?.data) {
      const matchingProject = projectList.data.find(
        (project) => project.projectId === projectId
      );
      setMarketId(matchingProject?.marketId);
    }
  }, [projectList, isProjectListLoading]);

  useEffect(() => {
    if (insights?.objectives && insights.objectives.length > 0) {
      let selectedObjectiveId = currentObjectiveId;

      if (!selectedObjectiveId || isNewProjectId) {
        selectedObjectiveId = insights.objectives[0].objectiveId;
        setCurrentObjectiveId(selectedObjectiveId);
        setIsNewProjectId(false);
      }

      const selectedObjective = insights?.objectives?.find(
        (obj) => obj.objectiveId === selectedObjectiveId
      );

      const hasMoreThanOneObjective = insights.objectives.length > 1;

      if (selectedObjective) {
        setActiveObjective(selectedObjective);
        setObjectivesRowActiveObjective({
          ...selectedObjective,
          id: selectedObjective.objectiveId,
          label: selectedObjective.objectiveDescription,
          maptualDisplayScore: selectedObjective.objectiveScore,
        });
        if (hasMoreThanOneObjective) {
          trackObjectiveSelection(selectedObjective, true);
        }
      }

      setViewMultiObjectives(hasMoreThanOneObjective);
    } else {
      setViewMultiObjectives(false);
    }

    return () => {
      setActiveObjective({});
      setObjectivesRowActiveObjective({});
      setViewMultiObjectives(false);
    };
  }, [insights, currentObjectiveId, isNewProjectId]);

  const [objectiveScores, setObjectiveScores] = useState([]);

  useEffect(() => {
    if (insights?.powerscore !== undefined) {
      setObjectiveScores(
        insights?.objectives?.map((obj) => Math.round(obj.objectiveScore))
      );
    } else {
      setObjectiveScores([]);
    }
  }, [insights]);

  const [insightsObjectives, setInsightsObjectives] = useState([]);
  useEffect(() => {
    if (insights?.objectives?.length > 0) {
      const objectives = [];

      insights.objectives.forEach((objective) => {
        objectives.push({
          ...objective,
          id: objective.objectiveId,
          label: objective.objectiveDescription,
          maptualDisplayScore: objective.objectiveScore,
        });
      });

      setInsightsObjectives(objectives);
    }
  }, [insights?.objectives]);

  const handleObjectiveChange = (obj) => {
    trackObjectiveSelection(obj, false);
    setActiveObjective(obj);
    setObjectivesRowActiveObjective(obj);
    setCurrentObjectiveId(obj.objectiveId);
  };

  const trackObjectiveSelection = (objective, defaultLoad) => {
    trackObjectiveSelected(
      `Objective ${objective.objectiveNumber}`,
      defaultLoad,
      'HCP Page Objective Selector Bar'
    );
  };

  const userGroup = profile?.userGroup;

  const [isAmaOptOut, setIsAmaOptOut] = useState<boolean | null>(null);

  useEffect(() => {
    if (!isEntityDetailsLoading && entity?.entityUData) {
      const amaOptOut = entity?.entityUData?.amaOptOut;
      setIsAmaOptOut(amaOptOut === null ? false : amaOptOut);
    }
  }, [isEntityDetailsLoading, entity?.entityUData]);

  return (
    <Scrollbar
      disableTracksWidthCompensation
      className={classes.root}
      data-testid="insightsView"
    >
      <div className={`${classes.container} scrollable`}>
        <div className={classes.content}>
          <EntityTitleBarContainer
            entity={entity}
            isEntityDetailsLoading={isEntityDetailsLoading}
            maptualScoreNormalized={
              insights?.powerscore !== undefined
                ? Math.round(insights.powerscore)
                : undefined
            }
            displayUserCreatedListsButton={displayUserCreatedListsButton}
            objectiveScores={objectiveScores}
            maptualListId={snowflakeMaptualListId}
            objectiveId={
              objectiveScores.length > 1 ? null : activeObjective?.objectiveId
            }
            isAmaOptOut={isAmaOptOut}
          />

          {viewMultiObjectives && (
            <MultiObjectivesRow
              objectives={insightsObjectives}
              handleClick={handleObjectiveChange}
              selectedObjective={objectivesRowActiveObjective}
              applyMargins={{ top: false, bottom: true }}
              entityType={entity?.metadata?.entityType}
              entityId={entity?.metadata?.entityId}
              entityName={entity?.metadata?.entityName}
              isNonDld={entity?.metadata?.isNonDld}
              isAmaOptOut={!!isAmaOptOut}
            />
          )}

          {isAmaOptOut === null && <SkeletonLoadingBar />}

          {isAmaOptOut === true && <AmaOptOutSection />}

          {isAmaOptOut === false && (
            <AmaOptInSection
              userGroup={userGroup}
              marketId={marketId}
              entity={entity}
              snowflakeEntityId={snowflakeEntityId}
              projectId={projectId}
              snowflakeMaptualListId={snowflakeMaptualListId}
              activeObjective={activeObjective}
            />
          )}
        </div>
      </div>
    </Scrollbar>
  );
};
