import InsightsCardRow from '../entityInsights/insightsCardRow';
import InsightsSegments from '../entityInsights/insightsSegments';
import { InsightsChannels } from '../entityInsights/insightsChannels';
import { InsightsPrecall } from '../entityInsights/insightsPrecall';
import { InsightsTopics } from '../entityInsights/insightsTopics';
import { InsightsWarningCard } from '../entityInsights/insightsWarningCard';

import useFeatureToggles from '../../../hooks/useFeatureToggles';
import { useModuleConfigurations } from '../../../hooks/useModuleConfigurations';
import { useObjectiveBaskets } from '../../../containers/application/appViews/field/hooks/useObjectiveBaskets';

import { CategoricalDataWrapper } from '../entityCategoricalData/categoricalDataWrapper';
import { EntityMessages } from '../entityMessages/entityMessages';
import { ProductPerformanceCard } from '../entityCharts/productPerformance';
import { MarketPerformanceCard } from '../entityCharts/marketPerformance';
import { OpportunityCard } from '../entityCharts/opportunity';
import { useEntityTopAttribution } from '../../../containers/fieldview/useEntityTopAttribution';
import { EntityType, ParamType } from '../../../clients/maptualAPI';

interface EntityChartsConfig {
  data: {
    showProductPerformanceChart: boolean;
  };
}
const hasValidMarketBasket = (objectiveBasket): boolean => {
  const competitorBasket = objectiveBasket[ParamType.ProductFrom];

  if (!competitorBasket || competitorBasket.products.length === 0) return false;

  const hasContinuousMetric: boolean = competitorBasket.metrics.some(
    (metric) => metric.dataType === 'continuous'
  );

  return hasContinuousMetric;
};

const hasValidOpportunityBasket = (objectiveBasket): boolean => {
  const validOpportunityBaskets = [
    ParamType.AnalogueProduct,
    ParamType.PrecursorProduct,
    ParamType.UnderlyingProduct,
  ];

  const hasAtLeastOneBasketWithProducts = validOpportunityBaskets.some(
    (basketType) => {
      const basket = objectiveBasket[basketType];
      return (
        basket &&
        basket.products.length > 0 &&
        basket.metrics.some(
          (metric) => metric.dataType === 'continuous' && metric.displayPriority
        )
      );
    }
  );

  return hasAtLeastOneBasketWithProducts;
};

export const AmaOptInSection = ({
  userGroup,
  marketId,
  entity,
  snowflakeEntityId,
  projectId,
  snowflakeMaptualListId,
  activeObjective,
}) => {
  const featureToggles = useFeatureToggles();

  const metadata = entity?.metadata || {};
  const isNoSee = entity?.metadata?.listMemberships?.noSee || false;
  const isLongTermLeave =
    entity?.metadata?.listMemberships?.longTermLeave || false;

  const { topAttributionData, isTopAttributionLoading } =
    useEntityTopAttribution({
      projectId,
      entityId: snowflakeEntityId,
    });

  const { data: entityChartsConfig } =
    useModuleConfigurations<EntityChartsConfig>({
      schemaName: 'entityCharts',
      marketId,
      userGroup,
      useDefaults: true,
    });

  const { basketsByObjective } = useObjectiveBaskets({
    projectId,
    entityType: EntityType.Hcp,
  });

  const objectiveBasket =
    basketsByObjective?.[activeObjective?.objectiveId] ?? {};

  return (
    <>
      {isNoSee && (
        <InsightsWarningCard
          name={metadata.entityName}
          userCreatedListType="No-See"
        />
      )}
      {isLongTermLeave && (
        <InsightsWarningCard
          name={metadata.entityName}
          userCreatedListType="Long-term Leave"
        />
      )}

      <InsightsChannels
        maptualTopChannels={topAttributionData?.maptualTopChannels || []}
        isLoading={isTopAttributionLoading}
      />

      {featureToggles(userGroup, 'showPrecallInsights') && (
        <InsightsPrecall
          entityId={snowflakeEntityId}
          objective={activeObjective}
          projectId={projectId}
          regionId={snowflakeMaptualListId}
        />
      )}

      {featureToggles(userGroup, 'showTopicsInsights') && (
        <InsightsTopics
          maptualTopTopics={topAttributionData?.maptualTopMessages || []}
          isLoading={isTopAttributionLoading}
        />
      )}

      <EntityMessages
        selectedObjective={activeObjective}
        entityId={snowflakeEntityId}
        projectId={projectId}
      />

      <CategoricalDataWrapper
        entityId={snowflakeEntityId}
        projectId={projectId}
        objectiveId={activeObjective?.objectiveId}
      />

      <InsightsCardRow objectiveCards={activeObjective?.cards || []} />

      <InsightsSegments objectiveCards={activeObjective?.cards || []} />

      {entityChartsConfig?.showProductPerformanceChart && (
        <ProductPerformanceCard
          projectId={projectId}
          regionId={snowflakeMaptualListId}
          objectiveId={activeObjective?.objectiveId}
          entityId={snowflakeEntityId}
        />
      )}

      {hasValidMarketBasket(objectiveBasket) && (
        <MarketPerformanceCard
          projectId={projectId}
          regionId={snowflakeMaptualListId}
          objectiveId={activeObjective?.objectiveId}
          entityId={snowflakeEntityId}
        />
      )}

      {hasValidOpportunityBasket(objectiveBasket) && (
        <OpportunityCard
          projectId={projectId}
          regionId={snowflakeMaptualListId}
          objectiveId={activeObjective?.objectiveId}
          entityId={snowflakeEntityId}
        />
      )}
    </>
  );
};
