import { useContext } from 'react';
import { styled, FormHelperText } from '@mui/material';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { DatePicker } from '@mui/x-date-pickers-pro';
import {
  StyledFieldSubtitle,
  StyledFieldTitle,
  StyledTextField,
} from './styles/styledComponents';
import { NewProjectContext } from './newProjectContext';
import NotchedModalDropdown from './notchedModalDropdown';
import NotchedObjectiveModalDropdown from './notchedObjectiveModalDropdown';
import { PROJECT_CREATION_STEPS } from './common/constants';
import { useDeployDialogContext } from '../projectviewer/deployCsvDialog/deployDialogContext';

const StyledNoDescriptionFieldTitle = styled(Typography)(() => ({
  marginBottom: 17,
}));

const StyledErrorText = styled(FormHelperText)(
  ({ theme: { themeColors } }) => ({
    lineHeight: '20px',
    color: themeColors.modalTextErrorColor,
  })
);

const ObjectiveTypeMenuItem = styled(MenuItem)({
  whiteSpace: 'normal',
  width: '400px',
  flexDirection: 'column',
  alignItems: 'baseline',
});

const ObjectiveTypeName = styled('p')({
  fontSize: '16px',
  margin: '0 0 5px 0',
});

const ObjectiveTypeSubtitle = styled('p')(({ theme: { themeColors } }) => ({
  fontSize: '14px',
  margin: '0px',
  color: themeColors.neutral60,
}));

const CustomDatePicker = styled(DatePicker)(({ theme: { themeColors } }) => ({
  '& .MuiInputLabel-root': {
    color: themeColors.neutral60,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: themeColors.neutral60,
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: themeColors.neutral60,
      borderWidth: '1px',
    },
    '&:hover .Mui-focused fieldset': {
      borderColor: themeColors.neutral60,
      borderWidth: '1px',
    },
    '&.Mui-focused fieldset': {
      borderColor: themeColors.neutral60,
      borderWidth: '1px',
    },
  },
}));

const ProjectModalForm = ({ productLines }) => {
  const {
    projectConfig,
    setProjectConfig,
    objectiveTypes,
    setProjectCreationStep,
    setObjectiveType,
    objectives,
  } = useContext(NewProjectContext);
  const { isTemplate } = useDeployDialogContext();
  return (
    <>
      <div>
        <StyledNoDescriptionFieldTitle variant="h5">
          {isTemplate ? 'Template Name' : 'Project Name'}
        </StyledNoDescriptionFieldTitle>
        <div>
          <StyledTextField
            focused
            value={projectConfig.projectName}
            onChange={(e) =>
              setProjectConfig({
                ...projectConfig,
                projectName: e.target.value,
              })
            }
          />
          {!projectConfig.projectName && (
            <StyledErrorText>
              {isTemplate
                ? 'Must add a project name'
                : 'Must add a template name'}
            </StyledErrorText>
          )}
        </div>
      </div>
      {!isTemplate && (
        <>
          <div>
            <StyledFieldTitle variant="h5">Project Due Date</StyledFieldTitle>
            <StyledFieldSubtitle>
              Select date to deploy project by in order to meet customer SLA
            </StyledFieldSubtitle>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDatePicker
                defaultValue={projectConfig.dueDate}
                onChange={(newDate) =>
                  setProjectConfig({
                    ...projectConfig,
                    dueDate: newDate,
                  })
                }
              />
            </LocalizationProvider>
          </div>

          <div>
            <StyledFieldTitle variant="h5">Changelog</StyledFieldTitle>
            <StyledFieldSubtitle>
              Summarize the changes included in the project
            </StyledFieldSubtitle>
            <StyledTextField
              multiline
              rows={2}
              focused
              value={projectConfig.changeLog}
              onChange={(e) =>
                setProjectConfig({
                  ...projectConfig,
                  changeLog: e.target.value,
                })
              }
            />
          </div>
        </>
      )}

      <NotchedModalDropdown
        title={isTemplate ? 'Customer Market' : 'Product Line'}
        subtitle={
          isTemplate
            ? 'Select a customer market to generate insights and recommendations for'
            : 'Select a product line to generate insights and recommendations for'
        }
        defaultValue={
          productLines.find(
            (productLine) => productLine.marketId === projectConfig.marketId
          ).productLine || 'Select Customer Market'
        }
        onChange={(e) =>
          setProjectConfig({
            ...projectConfig,
            productLineId: e.target.value.productLineId,
            productLineName: e.target.value.productLine,
            marketId: e.target.value.marketId,
          })
        }
        disabled={objectives.length}
        values={productLines}
      />

      <NotchedObjectiveModalDropdown
        title="Objectives"
        subtitle="Select and create an objective type. Must select at least 1"
        displayEmpty
        flip={objectiveTypes.length > 1}
        disabled={
          projectConfig.projectName === '' || projectConfig.productLineId === ''
        }
        onChange={(e) => {
          setObjectiveType(e.target.value);
          setProjectCreationStep(PROJECT_CREATION_STEPS.OBJECTIVE);
        }}
        values={objectiveTypes.map((type) => (
          <ObjectiveTypeMenuItem key={type.type} value={type}>
            <ObjectiveTypeName>{type.typeDisplay}</ObjectiveTypeName>
            <ObjectiveTypeSubtitle>
              {type.typeDescription}
            </ObjectiveTypeSubtitle>
          </ObjectiveTypeMenuItem>
        ))}
      />
    </>
  );
};

export default ProjectModalForm;
